<template>
  <div class="">
    <div class="min-w-0 pt-2">
      <div class="container mx-auto">
       <div>
          <div v-if="showTool" class="">
            <div class="mt-5 md:mt-0">
              <reports v-if="showReport"></reports>
              <distributor v-if="showDistributor"></distributor>
              <vendors v-if="showVendor"></vendors>
              <styles v-if="showStyles"></styles>
              <domains v-if="showDomains"></domains>
              <verify v-if="showVerify"></verify>
              <heatmap v-if="showHeatmap"></heatmap>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Reports from "@/components/ToolComponent/Reports";
import Distributor from "@/components/ToolComponent/Distributor";
import Vendors from "@/components/ToolComponent/Vendors";
import Styles from "@/components/ToolComponent/Styles";
import Domains from "@/components/ToolComponent/Domains";
import Verify from "@/components/ToolComponent/Verify";
import Heatmap from "@/components/ToolComponent/Heatmap";

export default {
name: "ContentPanel",
  components: {
    Reports,Distributor, Styles, Domains, Vendors, Heatmap, Verify
  },
  data(){
    return {
      showTool: false,
      showReport: true,
      showDistributor: false,
      showVendor: false,
      showStyles: false,
      showDomains: false,
      showVerify: false,
      showHeatMap: false
    }
  },
  beforeMount() {
    let host = window.location.host;
    let hostSplit = host.split('.');
    this.showSelected(hostSplit[0])
  },
  computed: {
    reportRegCss(){
      if(this.showReport){
        return{
          'color': '#ef4444'
        }
      } else {
        return{
          'color': '#000000'
        }
      }
    },
    distributorRegCss(){
      if(this.showReport){
        return{
          'color': '#ef4444'
        }
      } else {
        return{
          'color': '#000000'
        }
      }
    },
    styleRegCss(){
      if(this.showStyles){
        return{
          'color': '#ef4444'
        }
      } else {
        return{
          'color': '#000000'
        }
      }
    },
    domainRegCss(){
      if(this.showDomains){
        return{
          'color': '#ef4444'
        }
      } else {
        return{
          'color': '#000000'
        }
      }
    }
  },
  methods: {
    showSelected: function (type){
      switch (type){
        case 'tips':
        case 'report':
        case 'reports':
        case 'reports-dev':
        case 'report-dev':
          this.showReport = true;
          this.showDistributor = false;
          this.showVendor = false;
          this.showStyles = false;
          this.showDomains = false;
          this.showVerify = false;
          this.showTool = true;
          this.showHeatmap = false;
          document.title = "UABP Tips";
          break;
        case 'distributor':
        case 'distributors':
        case 'distributor-dev':
          this.showReport = false;
          this.showDistributor = true;
          this.showVendor = false;
          this.showStyles = false;
          this.showDomains = false;
          this.showVerify = false;
          this.showTool = true;
          this.showHeatmap = false;
          document.title = "Distributor Form";
          break;
        case 'vendors':
        case 'vendor':
        case 'vendor-dev':
        case 'vendors-dev':
          this.showReport = false;
          this.showDistributor = false;
          this.showVendor = true;
          this.showStyles = false;
          this.showDomains = false;
          this.showVerify = false;
          this.showTool = true;
          this.showHeatmap = false;
          document.title = "UABP Vendor";
          break;
        case 'domain':
        case 'domains':
        case 'domains-dev':
        case 'domain-dev':
          this.showReport = false;
          this.showDistributor = false;
          this.showVendor = false;
          this.showStyles = false;
          this.showDomains = true;
          this.showVerify = false;
          this.showTool = true;
          this.showHeatmap = false;
          document.title = "UABP Domains";
          break;
        case 'verify':
          this.showReport = false;
          this.showDistributor = false;
          this.showVendor = false;
          this.showStyles = false;
          this.showDomains = false;
          this.showVerify = true;
          this.showTool = true;
          this.showHeatmap = false;
          document.title = "UABP Domains";
          break;
        case 'styles':
        case 'style':
        case 'dls':
        case 'styles-dev':
        case 'style-dev':
        case 'dls-dev':
          this.showReport = false;
          this.showDistributor = false;
          this.showVendor = false;
          this.showStyles = true;
          this.showDomains = false;
          this.showVerify = false;
          this.showTool = true;
          this.showHeatmap = false;
          document.title = "UABP DLS";
          break;
        case 'heatmap':
        case 'heatmaps':
        case 'map':
        case 'heatmap-dev':
        case 'heatmaps-dev':
        case 'map-dev':
          this.showReport = false;
          this.showDistributor = false;
          this.showVendor = false;
          this.showStyles = false;
          this.showDomains = false;
          this.showVerify = false;
          this.showTool = true;
          this.showHeatmap = true;
          document.title = "UABP Heatmap";
          break;
        default:
          this.showReport = true;
          this.showDistributor = false;
          this.showVendor = false;
          this.showStyles = false;
          this.showDomains = false;
          this.showVerify = false;
          this.showTool = true;
          this.showHeatmap = false;
          document.title = "UABP Heatmap";
          break;
      }
    }
  }
}
</script>

<style scoped>

</style>