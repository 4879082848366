<template>
  <div v-if="properties">
    <div v-if="loading" class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
      <span class="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style="top: 50%;">
         <font-awesome-icon :icon="['fas', 'spinner']" size="5x" spin />
      </span>
    </div>
    <div v-if="done" class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-40">
      <span class="pr-16 text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
         <font-awesome-icon :icon="['fas', 'check']" size="5x" />
      </span>
    </div>
    <div v-if="done" class="w-full h-full z-50 fixed bg-transparent left-0 top-0">
      <p class="absolute w-full h-10 text-center mx-auto mt-24 top-1/2 text-gray-700 font-bold text-xl">Thank you for your tips!</p>
    </div>

    <form action="#" method="POST" ref="form" v-if="!loading && !done">
      <div class="shadow sm:rounded-md sm:overflow-hidden">
        <div class="px-4 py-5 bg-white sm:p-6">
        <!--<h1 class="text-l font-semibold">Report form</h1>-->
          <p class="mb-4 mt-2 text-m">Please fill in the form below and indicate as many details you can.<br>
            Please indicate if this is related to: Bad Websites, Social Media, Marketplaces, Shops/Markets, Imposters (illegitimate job offers, other suspicious activity)<br>
            or any other type of infringement.</p>
          <hr>
        </div>
        <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
         <!-- <div class="pb-3">
            <label for="subject" class="block text-sm font-medium text-gray-700">
              Subject<span class="text-red-500 pl-1">*</span>
            </label>
            <div class="mt-1 flex rounded-md shadow-sm">
              <input v-model="toSendProperty.subject" type="text" name="subject" id="subject" class="flex-1 block w-full rounded-sm sm:text-sm border-gray-300 focus:outline-none focus:text-gray-600 p-2" placeholder="Type object here" required>
            </div>
          </div>-->

          <div class="pb-10">
            <label for="description" class="block text-sm font-medium text-gray-700">
              Description<span class="text-red-500 pl-1">*</span>
            </label>
            <div class="mt-1">
              <vue-simplemde v-model="toSendProperty.description" ref="markdownEditor" class="pb-5" id="description" name="description" />
            </div>
          </div>
          <div class="pb-3 grid md:grid-cols-3 sm:grid-cols-1 gap-20">
            <!--<div class="col-span-1">
              <label for="start_date" class="block text-sm font-medium text-gray-700">
                Start date
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input type="date" name="start_date" id="start_date" class="flex-1 block w-full rounded-sm sm:text-sm border-gray-300 focus:outline-none focus:text-gray-600 p-2" placeholder="Type object here">
              </div>
            </div>-->
           <!-- <div class="col-span-2">
              <label :for="'cf_'+properties.submatter.id" class="block text-sm font-medium text-gray-700">
                {{properties.submatter.name}}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <select v-model="toSendProperty['cf_'+properties.submatter.id]" :name="'cf_'+properties.submatter.id" :id="'cf_'+properties.submatter.id" class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300">
                  <option v-for="submatterOption in properties.submatter.possible_values" :value="submatterOption.value">{{submatterOption.label}}</option>
                </select>
              </div>
            </div>-->
          </div>

          <div class="pb-3 grid md:grid-cols-4 sm:grid-cols-1 gap-20">
            <div class="col-span-2">
              <label :for="'cf_'+properties.region.id" class="block text-sm font-medium text-gray-700">
                {{properties.region.name}}<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <select v-model="toSendProperty['cf_'+properties.region.id]" :name="'cf_'+properties.region.id" :id="'cf_'+properties.region.id" class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300" required>
                  <option v-for="regionOption in properties.region.possible_values" :value="regionOption.value">{{regionOption.label}}</option>
                </select>
              </div>
            </div>
            <div class="col-span-2">
              <label :for="'cf_'+properties.country.id" class="block text-sm font-medium text-gray-700">
                {{properties.country.name}}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <select v-model="toSendProperty['cf_'+properties.country.id]" :name="'cf_'+properties.country.id" :id="'cf_'+properties.country.id" class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300">
                  <option v-for="countryOption in countries[toSendProperty['cf_'+properties.region.id]]" :value="countryOption">{{countryOption}}</option>
                </select>
              </div>
            </div>
            <div class="col-span-2">
              <label :for="'cf_'+properties.citystate.id" class="block text-sm font-medium text-gray-700">
                {{properties.citystate.name}}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input v-model="toSendProperty['cf_'+properties.citystate.id]" type="text" :name="'cf_'+properties.citystate.id" :id="'cf_'+properties.citystate.id" class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300" placeholder="City/State/Province">
              </div>
            </div>
            <div class="col-span-2">
              <label :for="'cf_'+properties.mapgps.id" class="block text-sm font-medium text-gray-700">
                {{properties.mapgps.name}}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input v-model="toSendProperty['cf_'+properties.mapgps.id]" type="text" :name="'cf_'+properties.mapgps.id" :id="'cf_'+properties.mapgps.id" class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300" placeholder="Map/Gps">
              </div>
            </div>
          </div>

          <div class="pb-3 grid md:grid-cols-3 sm:grid-cols-1 gap-20">
            <!--<div class="col-span-1">
              <label :for="'cf_'+properties.placeofinterest.id" class="block text-sm font-medium text-gray-700">
                {{properties.placeofinterest.name}}
              </label>
              <div class="mt-1 flex flex-col rounded-md shadow-sm" :name="'cf_'+properties.placeofinterest.id" :id="'cf_'+properties.placeofinterest.id">
                <label class="inline-flex items-center pt-4 pl-1">
                  <input type="radio" class="form-radio" :name="'cf_'+properties.placeofinterest.id" checked>
                  <span class="ml-2">(none)</span>
                </label>
                <label class="inline-flex items-center pt-4 pl-1" v-for="placeofinterestOption in properties.placeofinterest.possible_values">
                  <input v-model="toSendProperty['cf_'+properties.placeofinterest.id]" type="radio" class="form-radio" :name="'cf_'+properties.placeofinterest.id" :value="placeofinterestOption.value">
                  <span class="ml-2">{{placeofinterestOption.label}}</span>
                </label>
              </div>
            </div>-->
            <div class="col-span-2">
              <div class="col-span-1 rounded-md shadow-sm">
                <label :for="'cf_'+properties.offline.id" class="block text-sm font-medium text-gray-700">
                  Brick and Mortar?
                </label>
                <div class="mt-1 flex" :name="'cf_'+properties.offline.id" :id="'cf_'+properties.offline.id">
                  <label class="inline-flex items-center pl-1">
                    <input  type="radio" class="form-radio" :name="'cf_'+properties.offline.id" checked @click="toSendProperty['cf_'+properties.offline.id]=''">
                    <span class="ml-2">(none)</span>
                  </label>
                  <label class="inline-flex items-center pl-10" v-for="offlineOption in properties.offline.possible_values">
                    <input v-model="toSendProperty['cf_'+properties.offline.id]" type="radio" class="form-radio" :name="'cf_'+properties.offline.id" :value="offlineOption.value">
                    <span class="ml-2">{{ offlineOption.label }}</span>
                  </label>
                </div>
              </div>
             
              <div class="col-span-1 mt-4 rounded-md shadow-sm">
                <label :for="'cf_'+properties.online.id" class="block text-sm font-medium text-gray-700">
                  {{ properties.online.name }}
                </label>
                <div class="mt-1 flex" :name="'cf_'+properties.online.id" :id="'cf_'+properties.online.id">
                  <label class="inline-flex items-center pl-1">
                    <input type="radio" class="form-radio" :name="'cf_'+properties.online.id" checked @click="toSendProperty['cf_'+properties.online.id]=''">
                    <span class="ml-2">(none)</span>
                  </label>
                  <label class="inline-flex items-center pl-10" v-for="onlineOption in properties.online.possible_values">
                    <input v-model="toSendProperty['cf_'+properties.online.id]" type="radio" class="form-radio" :name="'cf_'+properties.online.id" :value="onlineOption.value">
                    <span class="ml-2">{{onlineOption.label}}</span>
                  </label>
                </div>
              </div>
              <div class="col-span-1">
                <div class="col-span-1 mt-4" v-if="toSendProperty['cf_'+properties.online.id]==1">
                  <label :for="'cf_'+properties.website.id" class="block text-sm font-medium text-gray-700">
                    {{ properties.website.name }}
                  </label>
                  <div class="mt-1 flex rounded-md shadow-sm">
                    <input v-model="toSendProperty['cf_'+properties.website.id]" type="text" :name="'cf_'+properties.website.id" :id="'cf_'+properties.website.id" class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300" placeholder="Website link">
                  </div>
                </div>
                <div class="col-span-1 mt-4" v-if="toSendProperty['cf_'+properties.offline.id]==1">
                  <label :for="'cf_'+properties.storelink.id" class="block text-sm font-medium text-gray-700">
                    {{ properties.storelink.name }}
                  </label>
                  <div class="mt-1 flex rounded-md shadow-sm">
                    <input v-model="toSendProperty['cf_'+properties.storelink.id]" type="text" :name="'cf_'+properties.storelink.id" :id="'cf_'+properties.storelink.id" class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300" placeholder="Store link">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pb-3 grid md:grid-cols-3 sm:grid-cols-1 gap-20">
            <!--<div class="col-span-1">
              <label :for="'cf_'+properties.producttype.id" class="block text-sm font-medium text-gray-700">
                {{ properties.producttype.name }}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <select v-model="toSendProperty['cf_'+properties.producttype.id]" :name="'cf_'+properties.producttype.id" :id="'cf_'+properties.producttype.id" class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300">
                  <option v-for="producttypeOption in properties.producttype.possible_values" :value="producttypeOption.value">{{producttypeOption.label}}</option>
                </select>
              </div>
            </div>-->
            <!--<div class="col-span-2">
              <label :for="'cf_'+properties.vendorid.id" class="block text-sm font-medium text-gray-700">
                {{ properties.vendorid.name }}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input v-model="toSendProperty['cf_'+properties.vendorid.id]" type="text" :name="'cf_'+properties.vendorid.id" :id="'cf_'+properties.vendorid.id" class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300" placeholder="Vendor ID">
              </div>
            </div>-->
          </div>


          <div class="pb-3 grid md:grid-cols-4 sm:grid-cols-1 gap-20">
            <div class="col-span-2">
              <label :for="'cf_'+properties.repnamesurname.id" class="block text-sm font-medium text-gray-700">
                {{ properties.repnamesurname.name }}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input v-model="toSendProperty['cf_'+properties.repnamesurname.id]" type="text" :name="'cf_'+properties.repnamesurname.id" :id="'cf_'+properties.repnamesurname.id" class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300" placeholder="Name Surname">
              </div>
            </div>
            <div class="col-span-2">
              <label :for="'cf_'+properties.repemail.id" class="block text-sm font-medium text-gray-700">
                {{ properties.repemail.name }}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input v-model="toSendProperty['cf_'+properties.repemail.id]" type="text" :name="'cf_'+properties.repemail.id" :id="'cf_'+properties.repemail.id" class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300" placeholder="Email">
              </div>
            </div>
            <div class="col-span-2">
              <label :for="'cf_'+properties.repphone.id" class="block text-sm font-medium text-gray-700">
                {{ properties.repphone.name }}
              </label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input v-model="toSendProperty['cf_'+properties.repphone.id]" type="text" :name="'cf_'+properties.repphone.id" :id="'cf_'+properties.repphone.id" class="focus:outline-none focus:text-gray-600 p-2 flex-1 block w-full rounded-sm sm:text-sm border-gray-300" placeholder="Phone number">
              </div>
            </div>
            <div class="col-span-2">
              <label :for="'cf_'+properties.contactme.id" class="block text-sm font-medium text-gray-700">
                {{properties.contactme.name}}<span class="text-red-500 pl-1">*</span>
              </label>
              <div class="mt-1 flex" :name="'cf_'+properties.contactme.id" :id="'cf_'+properties.contactme.id">
                <label class="inline-flex items-center pl-1" v-for="contactmeOption in properties.contactme.possible_values">
                  <input v-model="toSendProperty['cf_'+properties.contactme.id]" type="radio" class="form-radio" :name="'cf_'+properties.contactme.id" :value="contactmeOption.value">
                  <span class="ml-2">{{contactmeOption.label}}</span>
                </label>
              </div>
            </div>
          </div>
          <div v-cloak @drop.prevent="addFile" @dragover.prevent>
            <label class="block text-sm font-medium text-gray-700">
              Files
            </label>
            <div class="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div class="space-y-1 text-center">
                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <div class="flex text-sm text-gray-600">
                  <label for="file" class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                    <span>Upload a file</span>
                    <input ref="fileInput" id="file" name="file" type="file" class="sr-only" v-on:change="handleFileUpload()">
                  </label>
                  <p class="pl-1">or drag and drop</p>
                </div>
                <p class="text-xs text-gray-500">
                  PNG, JPG, PDF, XLS, DOC  up to 8MB
                </p>
              </div>
            </div>
            <ul class="mt-4">
              <li v-for="file in files" class="text-sm">
                {{ file.name }} ({{ file.size | kb }} kb) <button @click="removeFile(file)" title="Remove">x</button>
              </li>
            </ul>
          </div>
          <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" @click="submitForm()">
              Submit the case
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

</template>

<script>
import {axiosInstance as client} from "@/util/axios";
import VueSimplemde from 'vue-simplemde'

export default {
name: "Reports",
  components: {
    VueSimplemde
  },
  data(){
    return {
      properties: null,
      done: false,
      files:[],
      toSendProperty: [],
      loading:false,
      countries: {
        'AMERICAS': [
          "Anguilla",
          "Antigua and Barbuda",
          "Argentina",
          "Aruba",
          "Bahamas",
          "Barbados",
          "Belize",
          "Bermuda",
          "Bolivia",
          "Bonaire, Saint Eustatius and Saba",
          "Bouvet Island",
          "Brazil",
          "Canada",
          "Cayman Islands",
          "Chile",
          "Colombia",
          "Costa Rica",
          "Cuba",
          "Curaçao",
          "Dominica",
          "Dominican Republic",
          "Ecuador",
          "El Salvador",
          "Falkland Islands (Malvinas)",
          "French Guiana",
          "Grenada",
          "Guadeloupe",
          "Guatemala",
          "Guyana",
          "Haiti",
          "Honduras",
          "Invalid IP",
          "Jamaica",
          "Martinique",
          "Mexico",
          "Montserrat",
          "Netherlands Antilles",
          "Nicaragua",
          "Other",
          "Panama",
          "Paraguay",
          "Peru",
          "Puerto Rico",
          "Saint Barthelemy",
          "Saint Kitts and Nevis",
          "Saint Lucia",
          "Saint Martin",
          "Saint Pierre and Miquelon",
          "Saint Vincent and the Grenadines",
          "Sint Maarten",
          "South Georgia and the South Sandwich Islands",
          "Suriname",
          "Trinidad and Tobago",
          "Turks and Caicos Islands",
          "United States",
          "Uruguay",
          "Venezuela",
          "Virgin Islands, British",
          "Virgin Islands, U.S.",
        ],
        'APAC': [
          "Afghanistan",
          "Aland Islands",
          "American Samoa",
          "Armenia",
          "Australia",
          "Azerbaijan",
          "Bangladesh",
          "Bhutan",
          "Brunei",
          "Cambodia",
          "China",
          "Cook Islands",
          "Fiji",
          "French Polynesia",
          "Georgia",
          "Guam",
          "Hong Kong",
          "India",
          "Indonesia",
          "Japan",
          "Kazakhstan",
          "Kyrgyzstan",
          "Kiribati",
          "Laos",
          "Macao",
          "Malaysia",
          "Maldives",
          "Marshall Islands",
          "Micronesia",
          "Mongolia",
          "Myanmar",
          "Nauru",
          "Nepal",
          "New Caledonia",
          "New Zealand",
          "Niue",
          "North Korea",
          "Northern Mariana Islands",
          "Pakistan",
          "Palau",
          "Papua New Guinea",
          "Philippines",
          "Samoa",
          "Singapore",
          "Solomon Islands",
          "South Korea",
          "Sri Lanka",
          "Taiwan",
          "Thailand",
          "Tokelau",
          "Tonga",
          "Turkmenistan",
          "Tuvalu",
          "Vanuatu",
          "Vietnam",
          "Wallis And Futuna",
        ],
        'EMEA': [
          "Albania",
          "Algeria",
          "Andorra",
          "Angola",
          "Austria",
          "Bahrain",
          "Belarus",
          "Belgium",
          "Benin",
          "Bosnia and Herzegovina",
          "Botswana",
          "Bulgaria",
          "Burkina Faso",
          "Burundi",
          "Cameroon",
          "Cape Verde",
          "Central African Republic",
          "Chad",
          "Comoros",
          "Congo",
          "Congo, The Democratic Republic of the",
          "Côte D'Ivoire",
          "Croatia",
          "Cyprus",
          "Czech Republic",
          "Denmark",
          "Djibouti",
          "Egypt",
          "Equatorial Guinea",
          "England",
          "Eritrea",
          "Estonia",
          "Ethiopia",
          "Europe",
          "Faroe Islands",
          "Finland",
          "France",
          "France, Metropolitan",
          "Gabon",
          "Gambia",
          "Germany",
          "Ghana",
          "Gibraltar",
          "Greece",
          "Greenland",
          "Guernsey",
          "Guinea",
          "Guinea-Bissau",
          "Holy See (Vatican City State)",
          "Hungary",
          "Iceland",
          "Iran",
          "Iraq",
          "Ireland",
          "Isle of Man",
          "Israel",
          "Italy",
          "Jersey",
          "Jordan",
          "Kenya",
          "Kosovo",
          "Kuwait",
          "Latvia",
          "Lebanon",
          "Lesotho",
          "Liberia",
          "Libya",
          "Liechtenstein",
          "Lithuania",
          "Luxembourg",
          "Macedonia",
          "Madagascar",
          "Malawi",
          "Mali",
          "Malta",
          "Mauritania",
          "Mauritius",
          "Mayotte",
          "Moldova, Republic of",
          "Monaco",
          "Montenegro",
          "Morocco",
          "Mozambique",
          "Namibia",
          "Netherlands",
          "Niger",
          "Nigeria",
          "Norway",
          "OAPI",
          "Oman",
          "Palestinian Territory",
          "Poland",
          "Portugal",
          "Qatar",
          "Romania",
          "Russian Federation",
          "Rwanda",
          "Saint Helena",
          "San Marino",
          "Sao Tome and Principe",
          "Saudi Arabia",
          "Scotland",
          "Senegal",
          "Serbia",
          "Seychelles",
          "Sierra Leone",
          "Slovakia",
          "Slovenia",
          "Somalia",
          "South Africa",
          "South Sudan",
          "Spain",
          "Sudan",
          "Svalbard and Jan Mayen",
          "Swaziland",
          "Sweden",
          "Syrian Arab Republic",
          "Switzerland",
          "Tanzania, United Republic of",
          "Tadjikistan",
          "Togo",
          "Tunisia",
          "Turkey",
          "Uganda",
          "Ukraine",
          "United Arab Emirates",
          "United Kingdom",
          "Uzbekistan",
          "Wales",
          "WIPO",
          "Western Sahara",
          "Yemen",
          "Zambia",
          "Zimbabwe",
        ],
        'INTERNET': [
          "Market Place App & Social Media",
          "Domain",
          "Website",
          "Other",
        ],
        'GLOBAL': [
          "Other",
        ],
      }
    }
  },
  computed: {
    uploadDisabled() {
      return this.files.length === 0;
    }
  },
  beforeMount() {
    let self = this;
    self.getProperty();
  },
  methods: {
  //vmodel con i cf che sono i custom field
    getProperty: function (){
      client.get('/fields',{}).then((response) => {
        //console.log(response.data.fields)
        if(response.data.status === 'success'){
          this.loadProperty(response.data.fields);
        }
      }).catch( (error) => {
        console.log(error)
      }).finally( () => {
      });
    },
    loadProperty: function (data){
      let temp_properties= {}
      for(let i = 0; i < data.length; i++){
        switch (data[i].id) {
          case 2:
            temp_properties.submatter = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.submatter.id, temp_properties.submatter.default_value)
            break;
          case 39:
            temp_properties.region = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.region.id, temp_properties.region.default_value)
            break;
          case 4:
            temp_properties.country = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.country.id, temp_properties.country.default_value)
            break;
          case 15:
            temp_properties.citystate = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.citystate.id, temp_properties.citystate.default_value)
            break;
          case 40:
            temp_properties.mapgps = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.mapgps.id, temp_properties.mapgps.default_value)
            break;
          case 38:
            temp_properties.placeofinterest = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.placeofinterest.id, temp_properties.placeofinterest.default_value)
            break;
          case 108:
            temp_properties.offline = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.offline.id, temp_properties.offline.default_value)
            break;
          case 17:
            temp_properties.storelink = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.storelink.id, temp_properties.storelink.default_value)
            break;
          case 26:
            temp_properties.online = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.online.id, temp_properties.online.default_value)
            break;
          case 124:
            temp_properties.website = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.website.id, temp_properties.website.default_value)
            break;
          case 7:
            temp_properties.producttype = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.producttype.id, temp_properties.producttype.default_value)
            break;
          case 18:
            temp_properties.vendorid = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.vendorid.id, temp_properties.vendorid.default_value)
            break;
          case 120:
            temp_properties.repnamesurname = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.repnamesurname.id, temp_properties.repnamesurname.default_value)
            break;
          case 122:
            temp_properties.repemail = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.repemail.id, temp_properties.repemail.default_value)
            break;
          case 123:
            temp_properties.repphone = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.repphone.id, temp_properties.repphone.default_value)
            break;
          case 125:
            temp_properties.contactme = data[i];
            this.$set(this.toSendProperty, 'cf_'+temp_properties.contactme.id, temp_properties.contactme.default_value)
            break;
        }
      }
      this.properties = temp_properties;
      //console.log('prop', this.properties)
    },
    submitForm: function(){
      if (this.$refs.form.checkValidity()) {
        this.loading = true;
        let brick = this.toSendProperty['cf_'+this.properties.offline.id]
        let online = this.toSendProperty['cf_'+this.properties.online.id]
        let type = '';
        if(brick == '1'){
          type = 'Brick and Mortar'
        } else {
          type = 'Online'
        }
        let email = this.toSendProperty['cf_'+this.properties.repemail.id]
        let country = this.toSendProperty['cf_'+this.properties.country.id]
        
        this.toSendProperty['start_date'] = (new Date()).toISOString().split('T')[0]
        this.toSendProperty.subject = 'Tips '+ this.toSendProperty['start_date'] + ' - ' + type + ' - From ' + email + ' - ' + country 


        let data = this.toSendProperty
        const formData = new FormData();
        
        Object.entries(this.toSendProperty).forEach(([key, value]) => {
          formData.append(key, value);
        });

        Object.entries(this.files).forEach(([key, value]) => {
          formData.append('file_'+key, value);
        });
    
        
        //formData.append('files', this.files);
        var querystring = require('querystring');
        client.post('/case',formData).then((response) => {
          console.log(response.data)
          if(response.data.status === 'success'){
            this.done = true;
          }
        }).catch( (error) => {
          console.log(error)
        }).finally( () => {
          this.loading = false;
        });
      } else {
        this.$refs.form.reportValidity();
      }
    },
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if(!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      ([...droppedFiles]).forEach(f => {
        this.files.push(f);
      });
    },
    removeFile(file){
      this.files = this.files.filter(f => {
        return f != file;
      });      
    },
    handleFileUpload(){
      this.files.push(this.$refs.fileInput.files[0]);
    }
  },
  watch: {
    toSendProperty: {
      // This will let Vue know to look inside the array
      deep: true,
      // We have to move our method to a handler field
      handler(val) {
        //console.log(this.toSendProperty);
        if(this.toSendProperty['cf_'+this.properties.submatter.id] == 'Internet' || this.toSendProperty['cf_'+this.properties.submatter.id] == 'Market Place, App & Social Media' || this.toSendProperty['cf_'+this.properties.submatter.id] == 'Domains & Websites'){
          this.$set(this.toSendProperty, 'cf_'+this.properties.online.id, 1)
          this.$set(this.toSendProperty, 'cf_'+this.properties.offline.id, 0)
        } else {
          this.$set(this.toSendProperty, 'cf_'+this.properties.online.id, '')
          this.$set(this.toSendProperty, 'cf_'+this.properties.offline.id, '')
        }
      }
      
    }
  }
}
</script>

<style scoped>
@import '~simplemde/dist/simplemde.min.css';
label.block{
  font-weight:600;
}
</style>