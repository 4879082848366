<template>
  <div>
    <div class="bg-white" style="min-height: calc(100vh - 250px)" v-if="!showMap">
      <main class="flex-1 flex overflow-hidden h-full">
        <div class="flex-1 flex flex-col overflow-y-auto xl:overflow-hidden h-full">
          <!-- Breadcrumb -->
          <nav aria-label="Breadcrumb" class="bg-white border-b border-blue-gray-200 xl:hidden">
            <div class="max-w-3xl mx-auto py-3 px-4 flex items-start sm:px-6 lg:px-8">
              <a href="#" class="-ml-1 inline-flex items-center space-x-3 text-sm font-medium text-blue-gray-900">
                <!-- Heroicon name: solid/chevron-left -->
                <svg class="h-5 w-5 text-blue-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span>Settings</span>
              </a>
            </div>
          </nav>

          <div class="flex-1 flex xl:overflow-hidden h-full">
            <!-- Secondary sidebar -->
            <nav aria-label="Sections" class="hidden flex-shrink-0 w-96 bg-white border-r border-blue-gray-200 xl:flex xl:flex-col h-full">
              <div class="flex-shrink-0 h-16 px-6 border-b border-blue-gray-200 flex items-center justify-between">
                <p class="text-lg font-medium text-blue-gray-900">Settings</p>
                <button @click="resetSettings()" class="btn bg-red-500 text-white border rounded text-sm p-1 px-2">Reset</button>
              </div>
              <div class="flex-1 min-h-0 overflow-y-auto">
                <!-- Current: "bg-blue-50 bg-opacity-50", Default: "hover:bg-blue-50 hover:bg-opacity-50" -->
                <a href="#" :class="{'bg-blue-50 bg-opacity-50': active.places}" class="hover:bg-blue-50 hover:bg-opacity-50 flex p-6 border-b border-blue-gray-200" aria-current="page" @click="switchPanel('places')">
                  <!-- Heroicon name: outline/cog -->
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <div class="ml-3 text-sm">
                    <p class="font-medium text-blue-gray-900">Places</p>
                    <p class="mt-1 text-blue-gray-500">Pick your Region & Countries.</p>
                  </div>
                </a>

                <a href="#" :class="{'bg-blue-50 bg-opacity-50': active.issues}" class="hover:bg-blue-50 hover:bg-opacity-50 flex p-6 border-b border-blue-gray-200" @click="switchPanel('issues')">
                  <!-- Heroicon name: outline/bell -->
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                  </svg>
                  <div class="ml-3 text-sm">
                    <p class="font-medium text-blue-gray-900">Issues</p>
                    <p class="mt-1 text-blue-gray-500">Pick your Issues</p>
                  </div>
                </a>

                <a href="#" :class="{'bg-blue-50 bg-opacity-50': active.contacts}" class="hover:bg-blue-50 hover:bg-opacity-50 flex p-6 border-b border-blue-gray-200" @click="switchPanel('contacts')">
                  <!-- Heroicon name: outline/key -->
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                  <div class="ml-3 text-sm">
                    <p class="font-medium text-blue-gray-900">Contacts</p>
                    <p class="mt-1 text-blue-gray-500">Pick your Contacts</p>
                  </div>
                </a>

                <a href="#" :class="{'bg-blue-50 bg-opacity-50': active.appearance}" class="hover:bg-blue-50 hover:bg-opacity-50 flex p-6 border-b border-blue-gray-200" @click="switchPanel('appearance')">
                  <!-- Heroicon name: outline/photograph -->
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                  </svg>
                  <div class="ml-3 text-sm">
                    <p class="font-medium text-blue-gray-900">Appearance</p>
                    <p class="mt-1 text-blue-gray-500">Select Display Mode</p>
                  </div>
                </a>

              </div>
            </nav>

            <!-- Main content -->
            <div class="flex-1 max-h-screen xl:overflow-y-auto h-full relative">
              <!-- Places -->
              <div class="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8" v-show="active.places">
                <h1 class="text-3xl font-extrabold text-blue-gray-900">Configure Places</h1>
                 <div class="sm:col-span-6">
                    <p class="mt-1 text-sm text-blue-gray-500">Pick your Region & Countries to show up on the map.</p>
                  </div>
                <div class="mt-6 space-y-8 ">
                  <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-12 sm:gap-x-6">

                    <div class="col-span-5">
                      <t-select
                        placeholder="Select Region"
                        :options="regions"
                        v-model="region"
                        variant="demo"
                      ></t-select>
                    </div>

                    <div class="col-span-5">
                      <t-select
                        placeholder="Select Country"
                        :options="countries[region]"
                        variant="demo"
                        v-model="country"
                        :disabled="!region"
                      ></t-select>
                    </div>

                    <div class="flex justify-end align-middle items-center col-span-2">
                      <span class="ml-4 flex-shrink-0 flex items-start space-x-4">
                        <button type="button" class="bg-white rounded-md font-medium text-green-600 hover:text-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500" @click="setValue">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </button>
                      </span>
                    </div>
                   
                  </div>
                  
                  <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-12 sm:gap-x-6">
                    <div class="col-span-12" v-if="places.length">
                      <h3 class="text-lg font-bold text-blue-gray-900">Places added to the map</h3>
                      <!--<p class="text-sm text-blue-gray-500">You can remove places clicking on x simbol</p>-->
                    </div>
                    <div v-else class="col-span-12 flex justify-items-start">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <h3 class="text-lg font-bold text-blue-gray-900 ml-2">Add at least one Region to proceed</h3>
                    </div>
                    <div class="col-span-12">
                      <span v-for="(place, index) in places" :key="index" class="inline-flex rounded-full items-center py-1 pl-3 pr-1.5 text-sm font-medium bg-yellow-100 text-grey-700 mr-2 mb-2">
                        {{place.region}}/{{place.country}}
                        <button type="button" @click="places.splice(index, 1)" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-grey-400 hover:bg-red-500 hover:text-white focus:outline-none focus:bg-red-500 focus:text-white">
                          <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                          </svg>
                        </button>
                      </span>
                    </div>
                  </div>
                  <hr/>

                  <div class="pt-8 flex justify-end">
                    <button type="button"  class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      Cancel
                    </button>
                    <button type="button" @click="next('places','issues')" v-show="places.length" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Next</button>
                  </div>
                </div>
              </div>
              <!-- Issues -->
              <div class="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8" v-show="active.issues">
                <h1 class="text-3xl font-extrabold text-blue-gray-900">Configure Issues</h1>
                 <div class="sm:col-span-6">
                    <p class="mt-1 text-sm text-blue-gray-500">Pick your Issues to show up on the map.</p>
                  </div>
                <div class="mt-6 space-y-8 ">

                  <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-12 sm:gap-x-6">
                        <div class="col-span-12 flex items-center">
                            <t-toggle checked
                            v-model="hideIssues"
                            ></t-toggle>
                            <span class="ml-3">
                              <span class="text-sm font-medium text-gray-900">Hide issues on map</span>
                            </span>
                        </div>
                        <div class="col-span-12 flex items-center" v-if="!hideIssues">
                            <t-toggle checked
                            v-model="viewAllIssues"
                            ></t-toggle>
                            <span class="ml-3">
                              <span class="text-sm font-medium text-gray-900">View all issues </span>
                              <span class="text-sm text-gray-500">(Default)</span>
                            </span>
                        </div>
                  </div>

                  <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-12 sm:gap-x-6" v-show="!viewAllIssues">

                    <div class="col-span-12">
                      <label>Select Trackers</label>
                      <div v-for="(option, index) in trackers" :key="index">
                        <label class="inline-flex items-center">
                          <input type="checkbox" v-model="tracker" :value="option.value" class="form-checkbox" checked />
                          <span class="ml-2">{{option.text}}</span>
                        </label>
                      </div>
                      <!--<t-select
                        valueAttribute="value"
                        textAttribute="text"
                        :multiple="true"
                        :options="trackers"
                        v-model="tracker"
                        variant="demo"
                      ></t-select>-->
                    </div>
                    <div class="col-span-12">
                      <label>Select Years</label>
                      <div v-for="(option, index) in years" :key="index">
                        <label class="inline-flex items-center">
                          <input type="checkbox" v-model="year" :value="option" class="form-checkbox" checked />
                          <span class="ml-2">{{option}}</span>
                        </label>
                      </div>
                      <!--<t-select
                        :multiple="true"
                        :options="years"
                        v-model="year"
                        variant="demo"
                      ></t-select>-->
                    </div>

                    <div class="col-span-12">
                      <label>Select Places of Interest</label>
                      <div v-for="(option, index) in placesOfInterest" :key="index">
                        <label class="inline-flex items-center">
                          <input type="checkbox" v-model="placeOfInterest" :value="option.value" class="form-checkbox" checked />
                          <span class="ml-2">{{option.text}}</span>
                        </label>
                      </div>
                    </div>

                    <div class="col-span-12 flex items-center">
                            <t-toggle
                            v-model="showFeatured"
                            ></t-toggle>
                            <span class="ml-3">
                              <span class="text-sm font-medium text-gray-900">Show only featured</span>
                            </span>
                    </div>
                    
                  </div>
                  
                  <hr/>

                  <div class="pt-8 flex justify-end">
                    <button type="button" @click="next('issues','places')" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      Previous
                    </button>
                    <button type="button" @click="next('issues','contacts')" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Next</button>
                  </div>
                </div>
              </div>
              <!-- Contacts -->
              <div class="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8" v-show="active.contacts">
                <h1 class="text-3xl font-extrabold text-blue-gray-900">Configure Contacts</h1>
                 <div class="sm:col-span-6">
                    <p class="mt-1 text-sm text-blue-gray-500">Pick your Contacts to show up on the map.</p>
                  </div>
                <div class="mt-6 space-y-8 ">

                  <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-12 sm:gap-x-6">
                    <div class="col-span-12 flex items-center">
                        <t-toggle checked
                        v-model="hideContacts"
                        ></t-toggle>
                        <span class="ml-3">
                          <span class="text-sm font-medium text-gray-900">Hide Contacts on map (Default)</span>
                        </span>
                    </div>
                    <div class="col-span-12 flex items-center" v-if="!hideContacts">
                        <t-toggle checked
                        v-model="viewAll"
                        ></t-toggle>
                        <span class="ml-3">
                          <span class="text-sm font-medium text-gray-900">View all contacts </span>
                          <span class="text-sm text-gray-500">(Default)</span>
                        </span>
                    </div>
                  </div>

                  <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-12 sm:gap-x-6" v-if="!hideContacts && !viewAll">

                    

                    <div class="col-span-5">
                      <t-select
                        placeholder="Select Category"
                        :options="contactsCategory"
                        v-model="contactCategory"
                        variant="demo"
                      ></t-select>
                    </div>

                    <div class="col-span-5" v-if="!hideContacts">
                      <t-select
                        placeholder="Select Subcategory"
                        :options="getSubCategory"
                        v-model="contactSubCategory"
                        variant="demo"
                        :disabled="!contactCategory"
                      ></t-select>
                    </div>

                    <div class="flex justify-end align-middle items-center col-span-2" v-if="!hideContacts">
                      <span class="ml-4 flex-shrink-0 flex items-start space-x-4">
                        <button type="button" class="bg-white rounded-md font-medium text-green-600 hover:text-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500" @click="addContact">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </button>
                      </span>
                    </div>
                   
                  </div>
                  
                  <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-12 sm:gap-x-6" v-if="!viewAll && !hideContacts">
                    <div class="col-span-12" v-if="places.length">
                      <h3 class="text-lg font-bold text-blue-gray-900">Contacts added to the map</h3>
                      <p class="text-sm text-blue-gray-500">You can remove contacts clicking on x simbol</p>
                    </div>
                    <div v-else class="col-span-12 flex justify-items-start">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <h3 class="text-lg font-bold text-blue-gray-900 ml-2">Add at least one Filter to proceed</h3>
                    </div>
                    <div class="col-span-12">
                      <span v-for="(contact, index) in contactsSettings" :key="index" class="inline-flex rounded-full items-center py-1 pl-3 pr-1.5 text-sm font-medium bg-indigo-100 text-grey-700 mr-2 mb-2">
                        {{contact.category}}/{{contact.subcategory}}
                        <button type="button" @click="contactsSettings.splice(index, 1)" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-grey-400 hover:bg-red-500 hover:text-white focus:outline-none focus:bg-red-500 focus:text-white">
                          <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                          </svg>
                        </button>
                      </span>
                    </div>
                  </div>
                  <hr/>

                  <div class="pt-8 flex justify-end">
                    <button type="button" @click="next('contacts','issues')" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      Previous
                    </button>
                    <button v-show="contactsSettings || viewAll" type="button" @click="next('contacts','appearance')" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Next</button>
                  </div>
                </div>
              </div>
              <!-- Appearance -->
              <div class="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8" v-show="active.appearance">
                <h1 class="text-3xl font-extrabold text-blue-gray-900">Configure Appearance</h1>
                 <div class="sm:col-span-6">
                    <p class="mt-1 text-sm text-blue-gray-500">Select Display Mode (single or duo).</p>
                  </div>
                <div class="mt-14 flex justify-center">
                  <button class="mx-auto w-6/12 p-2" v-on:click="selectBehavior('one')">
                    <div class="w-51.5 2xl:w-78 text-center rounded-md mx-auto"
                         :class="cardSelected.one ? 'bg-blue-50 h-56.9 2xl:h-82 p-4' : 'card-default-bg h-73 2xl:h-92'">
                      <div class="h-18 w-18 mx-auto mt-2" aria-hidden="true"/>
                      <div class="flex justify-center">
                        <img src="/map.png" class="w-6/12 h-auto responsive"/>
                      </div>
                      <h1 class="w-6/12 mx-auto text-gray-700 mt-6 text-sm leading-5 font-semibold">Single</h1>
                      <p class="mt-5 text-gray-700 text-xs leading-4 font-medium w-8/12 mx-auto">
                        Use one single map for Issues + Contacts
                      </p>
                    </div>
                  </button>
                  <button class="mx-auto w-6/12 p-2" v-on:click="selectBehavior('two')">
                    <div class="w-51.5 2xl:w-78 text-center rounded-md mx-auto"
                         :class="cardSelected.two ? 'bg-blue-50 h-56.9 2xl:h-82 p-4' : 'card-default-bg h-73 2xl:h-92'">
                      <div class="h-18 w-18 mx-auto mt-2" aria-hidden="true"/>
                      <div class="flex justify-center">
                        <img src="/map.png" class="w-6/12 h-auto responsive"/>
                        <img src="/map.png" class="responsive w-6/12 h-auto"/>
                      </div>
                      <h1 class="w-6/12 mx-auto text-gray-700 mt-6 text-sm leading-5 font-semibold">Double</h1>
                      <p class="mt-5 text-gray-700 text-xs leading-4 font-medium w-8/12 mx-auto">
                        Use two separate maps for Issues and Contacts
                      </p>
                    </div>
                  </button>
                </div>
                <hr/>
                <div class="pt-8 flex justify-end">
                    <button type="button" @click="next('contacts','issues')" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      Previous
                    </button>
                    <button v-show="contactsSettings || viewAll" type="button" @click="loadData()" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div class="bg-white w-full" v-else>
      <template>
        <div class="sticky h-20 p-5 top-0 z-50 bg-white border-b border-gray-300 border-b shadow-md overflow-hidden">
          <div class="flex flex-row-reverse w-full">
            <div class="mb-2">
              <button @click="showMap = false" class="mt-1 py-2 px-4 text-sm font-bold text-gray-500 rounded inline-flex items-center border border-gray-300 w-28 text-center">
                <svg class="h-5 w-5 fill-stroke text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z"></path>
                      <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                      <circle cx="12" cy="12" r="3"></circle>
                </svg>
                <span class="ml-2">Setting</span>
              </button>
            </div>
            <div class="p-1">
              <button @click="printPdf()" class="py-2 px-4 text-sm font-bold text-gray-500 rounded inline-flex items-center border border-gray-300  w-28 text-center">
                <svg class="h-4 w-4 text-gray-500" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="print" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M448 192V77.25c0-8.49-3.37-16.62-9.37-22.63L393.37 9.37c-6-6-14.14-9.37-22.63-9.37H96C78.33 0 64 14.33 64 32v160c-35.35 0-64 28.65-64 64v112c0 8.84 7.16 16 16 16h48v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h48c8.84 0 16-7.16 16-16V256c0-35.35-28.65-64-64-64zm-64 256H128v-96h256v96zm0-224H128V64h192v48c0 8.84 7.16 16 16 16h48v96zm48 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"></path></svg>
                <span class="ml-2">Reports</span>
              </button>
            </div>
            <div class="p-1">

              <a target="_blank" :href="'https://www.google.com/maps/dir/?api=1&origin='+center+'&destination='+points[0]+'&waypoints='+destination+'&travelmode=driving'" class="py-2 px-4 text-sm font-bold text-gray-500 rounded inline-flex items-center border border-gray-300  w-28 text-center">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="car" class="h-4 w-auto svg-inline--fa fa-car" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M39.61 196.8L74.8 96.29C88.27 57.78 124.6 32 165.4 32H346.6C387.4 32 423.7 57.78 437.2 96.29L472.4 196.8C495.6 206.4 512 229.3 512 256V448C512 465.7 497.7 480 480 480H448C430.3 480 416 465.7 416 448V400H96V448C96 465.7 81.67 480 64 480H32C14.33 480 0 465.7 0 448V256C0 229.3 16.36 206.4 39.61 196.8V196.8zM109.1 192H402.9L376.8 117.4C372.3 104.6 360.2 96 346.6 96H165.4C151.8 96 139.7 104.6 135.2 117.4L109.1 192zM96 256C78.33 256 64 270.3 64 288C64 305.7 78.33 320 96 320C113.7 320 128 305.7 128 288C128 270.3 113.7 256 96 256zM416 320C433.7 320 448 305.7 448 288C448 270.3 433.7 256 416 256C398.3 256 384 270.3 384 288C384 305.7 398.3 320 416 320z"></path></svg>
                <span class="ml-2">Driving</span>         
              </a>
            </div>
            <div class="p-1">
              <button @click="exportAll" class="py-2 px-4 text-sm font-bold text-gray-500 rounded inline-flex items-center border border-gray-300  w-28 text-center">
                <img class="h-4 w-auto" src="/download_icon.svg" alt="Download liquidated"/>
                <span class="ml-2">Exports</span>         
              </button>
            </div>
          </div>
        </div>
        <div ref="maps" class="relative z-10">
          <div class="flex w-full">
            <div class="flex-auto">
              <l-map style="height: 500px" :zoom="zoom" ref="first" @popupclose="activeIssue=null;activeContact=null">
                <l-tile-layer
                  :url="url"
                  :attribution="attribution"
                />
                <l-marker v-if="!hideIssues && issue.lon && issue.lat" @click="activeIssue=issue.id" :options="{id:issue.id}" :id="issue.id.toString()" :name="issue.id.toString()" :zIndexOffset="2" v-for="(issue, index) in issues" :key="issue.id" :lat-lng="[issue.lat.replace(' ','').replace('@','')-0.0001,issue.lon.replace(' ','').replace('@','')-0.0001]" :icon="getIcon(issue)">
                  <l-popup @popupclose="activeIssue=null">
                    <p>
                      <a :href="'https://charlie.uabp.eu/issues/'+issue.id" target="_blank">
                        {{issue.id}}
                      </a> - {{issue.total_units}} {{issue.product_type}}
                    <br>
                    {{issue.city}}
                    <br>
                    {{issue.start_date}}
                    </p>
                    <p>
                      <a target="_blank" :href="'https://www.google.com/maps/dir/?api=1&origin='+center+'&destination='+issue.lat+','+issue.lon+'&travelmode=driving'">
                        Directions
                      </a>
                    </p>
                  </l-popup>
                </l-marker>
                <l-marker @click="activeContact=issue.id" v-if="cardSelected.one && !hideContacts && contact.map" v-for="(contact, index) in contactsList" :key="index" :lat-lng="contact.map.split(',')" :options="{id:contact.id}" :icon="getIconContacts(contact)" >
                  <l-popup>
                    <p>
                      <a :href="'https://charlie.uabp.eu/contacts/'+contact.id" target="_blank">
                        {{contact.id}}
                      </a> - {{contact.first_name}} {{contact.last_name}}<br>{{contact.subcategory}}
                    </p>
                    <p>
                      <a target="_blank" :href="'https://www.google.com/maps/dir/?api=1&origin='+center+'&destination='+contact.map.split(', ')+'&travelmode=driving'">
                        Directions
                      </a>
                    </p>
                    
                  </l-popup>
                </l-marker>
              </l-map>
            </div>
            <div class="w-5" v-if="cardSelected.two">
            </div>
            <div class="flex-auto" v-if="cardSelected.two">
            <l-map style="height: 500px;" :zoom="zoom" ref="second" @popupclose="activeIssue=null;activeContact=null">
                <l-tile-layer
                  :url="url"
                  :attribution="attribution"
                />
                <l-marker v-if="!hideContacts" @click="activeContact=issue.id" v-for="(contact, index) in contactsList" :key="index+'_c'" :lat-lng="contact.map.replace('@','').split(',')" :options="{id:contact.id}" :icon="getIconContacts(contact)" >
                  <l-popup>
                    <p>
                      <a :href="'https://charlie.uabp.eu/contacts/'+contact.id" target="_blank">
                        {{contact.id}}
                      </a> - {{contact.first_name}} {{contact.last_name}}<br>{{contact.subcategory}}
                    </p>
                    <p>
                      <a target="_blank" :href="'https://www.google.com/maps/dir/?api=1&origin='+center+'&destination='+contact.map.replace('@','').split(',')[0]+','+contact.map.replace('@','').split(',')[1]+'&travelmode=driving'">
                        Directions
                      </a>
                    </p>
                    
                  </l-popup>
                </l-marker>
              </l-map>
            </div>
          </div>
          <div class="legend w-full bg-white p-5">
            <div class="flex justify-start mb-2">
              <div class="flex justify-start items-center w-40"><img :src="returnIcon('(C)ustoms')" class="w-6 mr-3" /> <span>Customs</span></div>
              <div class="flex justify-start items-center w-40"><img :src="returnIcon('(F)actory')" class="w-6 mr-3" /> <span>Factory</span></div>
              <div class="flex justify-start items-center w-40"><img :src="returnIcon('Factory')" class="w-6 mr-3" /> <span>Factory Audit</span></div>
              <div class="flex justify-start items-center w-40"><img :src="returnIcon('UA')" class="w-6 mr-3" /> <span>UA</span></div>
              <div class="flex justify-start items-center w-40"><img :src="returnIcon('Partner')" class="w-6 mr-3" /> <span>Partner</span></div>
            </div>
            <div class="flex justify-start">
              <div class="flex justify-start items-center w-40"><img :src="returnIcon('(S)hop')" class="w-6 mr-3" /> <span>Shop</span></div>
              <div class="flex justify-start items-center w-40"><img :src="returnIcon('(W)arehouse')" class="w-6 mr-3" /> <span>Wharehouse</span></div>
              <div class="flex justify-start items-center w-40"><img :src="returnIcon('Trainings')" class="w-6 mr-3" /> <span>Trainings</span></div>
              <div class="flex justify-start items-center w-40"><img :src="returnIcon('bad')" class="w-6 mr-3" /> <span>Bad Guys</span></div>
            </div>
           
          </div>
        </div>
        <!-- CASES -->
        <div class="w-full px-4 mx-auto mt-5" >
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded ">
            <div class="rounded-t mb-0 px-4 py-3 border-0">
              <div class="flow-root">
                    <h3 class="font-semibold text-base text-blueGray-700 float-left">Cases: {{cases.length}}</h3>
                    <button @click="cases_filters_view=!cases_filters_view" class="float-right text-gray-500 border border-gray-200 flex flex-inline items-center px-2 py-1 rounded">
                      <svg class="h-5 w-5 fill-stroke text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z"></path>
                            <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                      <span class="ml-2 text-sm">Config</span>
                    </button>
                    <button @click="tableCsv('issues-table')" class="mr-2 float-right text-gray-500 border border-gray-200 flex flex-inline items-center px-2 py-1 rounded">
                      <img class="h-4 w-auto" src="/download_icon.svg" alt="Download liquidated"/>
                      <span class="ml-2 text-sm">Export</span>
                    </button>
                    
              </div>
            </div>
            <div v-if="cases_filters_view" class="mb-4">
              <div class="grid grid-cols-4 flex justify-between mb-4">
                <div class="ml-3 flex items-center h-5" v-for="(item, index) in columns.cases" :key="index">
                  <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="columns.cases[index]"/>
                  <label class="ml-2 capitalize">{{index.replaceAll('_',' ')}}</label>
                </div>
              </div>
            </div>
            <div class="block w-full overflow-x-auto max-h-96">
              <table class="table-sort remember-sort cases items-center bg-transparent w-full border-collapse " id="issues-table">
                <thead class="sticky top-0 bg-white">
                  <tr>
                    <th class="order-by-desc px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.cases.id">
                                  #
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.cases.country">
                                  Country
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.cases.city">
                                  City/State/Province
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.cases.map_gps">
                                  Map/GPS
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.cases.total_units">
                                  Total Units
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.cases.product_type">
                                  Product Type
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.cases.start_date">
                                  Start Date
                                </th>
                  
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.cases.place_of_interest">
                                  Place Of Interest
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.cases.custom_type">
                                  Custom Type
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.cases.law_enforcement">
                                  Law Enforcement
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.cases.vendor">
                                  Vendor
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.cases.infringer_1">
                                  Infringer 1
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.cases.infringer_2">
                                  Infringer 2
                                </th>
                  </tr>
                </thead>

                <tbody class="overflow-y-scroll">
                  <tr v-for="(issue, index) in cases" :key="index" :class="[(activeIssue === issue.id) ? 'bg-green-400' : '', (index % 2 === 0) ? 'bg-gray-100' : '']">
                    <td class="w-max-5 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left text-blueGray-700 p-2 " v-show="columns.cases.id">
                      <div class="flex justify-between items-center">
                        <span>
                          <input type="checkbox" name="points" :value="issue.lat.replace('@','')+','+issue.lon" v-model="points" class="mr-2"/>
                          <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white w-20 bg-green-400 mr-2 rounded">
                            <a :href="'https://charlie.uabp.eu/issues/'+issue.id" target="_blank">#{{issue.id}}</a>
                          </span>
                        </span>
                        <img :src="returnIcon(issue.place_of_interest)" class="w-6 mr-3" /> 
                        <a class="block w-4 h-4 ml-2" target="_blank" :href="'https://www.google.com/maps/dir/?api=1&origin='+center+'&destination='+issue.lat+','+issue.lon+'&travelmode=driving'">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="car" class="svg-inline--fa fa-car" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M39.61 196.8L74.8 96.29C88.27 57.78 124.6 32 165.4 32H346.6C387.4 32 423.7 57.78 437.2 96.29L472.4 196.8C495.6 206.4 512 229.3 512 256V448C512 465.7 497.7 480 480 480H448C430.3 480 416 465.7 416 448V400H96V448C96 465.7 81.67 480 64 480H32C14.33 480 0 465.7 0 448V256C0 229.3 16.36 206.4 39.61 196.8V196.8zM109.1 192H402.9L376.8 117.4C372.3 104.6 360.2 96 346.6 96H165.4C151.8 96 139.7 104.6 135.2 117.4L109.1 192zM96 256C78.33 256 64 270.3 64 288C64 305.7 78.33 320 96 320C113.7 320 128 305.7 128 288C128 270.3 113.7 256 96 256zM416 320C433.7 320 448 305.7 448 288C448 270.3 433.7 256 416 256C398.3 256 384 270.3 384 288C384 305.7 398.3 320 416 320z"></path></svg>
                          </a>
                        </div>
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.cases.country">
                      {{issue.country}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.cases.city">
                      {{issue.city}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.cases.map_gps">
                      <a target="_blank" :href="'https://maps.google.com?q='+issue.lat+','+issue.lon">{{issue.lat}}/{{issue.lon}}</a>
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.cases.total_units">
                      {{issue.total_units}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.cases.product_type">
                      {{issue.product_type}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.cases.start_date">
                      {{issue.start_date}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.cases.place_of_interest">
                      <div class="flex justify-start items-center">
                        <span>{{issue.place_of_interest}}</span>
                      </div>
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.cases.custom_type">
                      {{issue.custom_type}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.cases.law_enforcement">
                      {{issue.law_enforcement}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.cases.vendor">
                      {{issue.vendor}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.cases.infringer_1">
                      {{issue.infringer1}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.cases.infringer_2">
                      {{issue.infringer2}}
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>
          </div>
        </div>


        <!-- TRAININGS -->
        <div class="w-full px-4 mx-auto mt-5" > 
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded ">
            <div class="rounded-t mb-0 px-4 py-3 border-0">
              <div class="flow-root">
                    <h3 class="font-semibold text-base text-blueGray-700">Trainings: {{trainings.length}}</h3>
                    <button @click="trainings_filters_view=!trainings_filters_view" class="float-right text-gray-500 border border-gray-200 flex flex-inline items-center px-2 py-1 rounded">
                      <svg class="h-5 w-5 fill-stroke text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z"></path>
                            <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                      <span class="ml-2 text-sm">Config</span>
                    </button>
                    <button @click="tableCsv('trainings-table')" class="mr-2 float-right text-gray-500 border border-gray-200 flex flex-inline items-center px-2 py-1 rounded">
                      <img class="h-4 w-auto" src="/download_icon.svg" alt="Download"/>
                      <span class="ml-2 text-sm">Export</span>
                    </button>
              </div>
            </div>
            <div v-if="trainings_filters_view" class="mb-4">
              <div class="grid grid-cols-4 flex justify-between mb-4">
                <div class="ml-3 flex items-center h-5" v-for="(item, index) in columns.trainings" :key="index">
                  <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="columns.trainings[index]"/>
                  <label class="ml-2 capitalize">{{index.replaceAll('_',' ')}}</label>
                </div>
              </div>
            </div>
            <div class="block w-full overflow-x-auto max-h-96">
              <table class="table-sort trainings items-center bg-transparent w-full border-collapse " id="trainings-table">
                <thead class="sticky top-0 bg-white">
                  <tr>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.trainings.id">
                                  #
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.trainings.country">
                                  Country
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.trainings.city">
                                  City/State/Province
                                </th>

                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.trainings.map_gps">
                                  Map/GPS
                                </th>
                    
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.trainings.start_date">
                                  Start Date
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.trainings.subject">
                                  Subject
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.trainings.law_enforcement">
                                  Law Enforcement
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.trainings.vendor">
                                  Vendor
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.trainings.attendees">
                                  Attendees
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.trainings.visit">
                                  Visit
                                </th>
                  </tr>
                </thead>

                <tbody class="overflow-y-scroll">
                  <tr v-for="(issue, index) in trainings" :key="index" :class="[(activeIssue === issue.id) ? 'bg-green-400' : '', (index % 2 === 0) ? 'bg-gray-100' : '']">
                    <td class="w-max-5 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left text-blueGray-700 p-2 " v-show="columns.trainings.id">
                      <div class="flex justify-between items-center">
                        <span>
                          <input type="checkbox" name="points" :value="issue.lat.replace('@','')+','+issue.lon" v-model="points" class="mr-2"/>
                          <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white w-20 bg-green-400 mr-2 rounded"><a :href="'https://charlie.uabp.eu/issues/'+issue.id" target="_blank">#{{issue.id}}</a></span>
                        </span>

                        <img :src="require('../../assets/markers/marker-5.png')" class="w-6 mr-3" /> 
                        <a class="block w-4 h-4 ml-2" target="_blank" :href="'https://www.google.com/maps/dir/?api=1&origin='+center+'&destination='+issue.lat+','+issue.lon+'&travelmode=driving'">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="car" class="svg-inline--fa fa-car" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M39.61 196.8L74.8 96.29C88.27 57.78 124.6 32 165.4 32H346.6C387.4 32 423.7 57.78 437.2 96.29L472.4 196.8C495.6 206.4 512 229.3 512 256V448C512 465.7 497.7 480 480 480H448C430.3 480 416 465.7 416 448V400H96V448C96 465.7 81.67 480 64 480H32C14.33 480 0 465.7 0 448V256C0 229.3 16.36 206.4 39.61 196.8V196.8zM109.1 192H402.9L376.8 117.4C372.3 104.6 360.2 96 346.6 96H165.4C151.8 96 139.7 104.6 135.2 117.4L109.1 192zM96 256C78.33 256 64 270.3 64 288C64 305.7 78.33 320 96 320C113.7 320 128 305.7 128 288C128 270.3 113.7 256 96 256zM416 320C433.7 320 448 305.7 448 288C448 270.3 433.7 256 416 256C398.3 256 384 270.3 384 288C384 305.7 398.3 320 416 320z"></path></svg>
                          </a>
                        </div>
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.trainings.country">
                      {{issue.country}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.trainings.city">
                      {{issue.city}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.trainings.map_gps">
                      <a target="_blank" :href="'https://maps.google.com?q='+issue.lat+','+issue.lon">{{issue.lat}}/{{issue.lon}}</a>
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.trainings.start_date">
                      {{issue.start_date}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.trainings.subject">
                      {{issue.subject}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.trainings.law_enforcement">
                      {{issue.law_enforcement}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.trainings.vendor">
                      {{issue.vendor}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.trainings.attendees">
                      {{issue.attendees}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.trainings.visit">
                      {{issue.visit}}
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>
          </div>
        </div>


        <!-- FACTORY -->
        <div class="w-full px-4 mx-auto mt-5" >
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded ">
            <div class="rounded-t mb-0 px-4 py-3 border-0">
              <div class="flow-root">
                    <h3 class="font-semibold text-base text-blueGray-700">Factory Audit: {{factory.length}}</h3>
                    <button @click="factories_filters_view=!factories_filters_view" class="float-right text-gray-500 border border-gray-200 flex flex-inline items-center px-2 py-1 rounded">
                      <svg class="h-5 w-5 fill-stroke text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z"></path>
                            <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                      <span class="ml-2 text-sm">Config</span>
                    </button>
                    <button @click="tableCsv('factory-table')" class="mr-2 float-right text-gray-500 border border-gray-200 flex flex-inline items-center px-2 py-1 rounded">
                      <img class="h-4 w-auto" src="/download_icon.svg" alt="Download"/>
                      <span class="ml-2 text-sm">Export</span>
                    </button>
              </div>
            </div>
            <div v-if="factories_filters_view" class="mb-4">
              <div class="grid grid-cols-4 flex justify-between mb-4">
                <div class="ml-3 flex items-center h-5" v-for="(item, index) in columns.factories" :key="index">
                  <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="columns.factories[index]"/>
                  <label class="ml-2 capitalize">{{index.replaceAll('_',' ')}}</label>
                </div>
              </div>
            </div>
            <div class="block w-full overflow-x-auto max-h-96">
              <table class="table-sort factory items-center bg-transparent w-full border-collapse " id="factory-table">
                <thead class="sticky top-0 bg-white">
                  <tr>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.factories.id">
                                  #
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.factories.country">
                                  Country
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.factories.city">
                                  City/State/Province
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.factories.map_gps">
                                  Map/GPS
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.factories.product_type">
                                  Product Type
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.factories.start_date">
                                  Start Date
                                </th>
                  </tr>
                </thead>

                <tbody class="overflow-y-scroll">
                  <tr v-for="(issue, index) in factory" :key="index" :class="[(activeIssue === issue.id) ? 'bg-green-400' : '', (index % 2 === 0) ? 'bg-gray-100' : '']">
                    <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left text-blueGray-700 p-2 " v-show="columns.factories.id">
                      <div class="flex justify-between items-center">
                        <span>
                          <input type="checkbox" name="points" :value="issue.lat.replace('@','')+','+issue.lon" v-model="points" class="mr-2"/>
                          <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white w-20 bg-green-400 mr-2 rounded"><a :href="'https://charlie.uabp.eu/issues/'+issue.id" target="_blank">#{{issue.id}}</a></span>
                        </span>
                        <img :src="require('../../assets/markers/marker-4.png')" class="w-6 mr-3" /> 
                        <a class="block w-4 h-4 ml-2" target="_blank" :href="'https://www.google.com/maps/dir/?api=1&origin='+center+'&destination='+issue.lat+','+issue.lon+'&travelmode=driving'">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="car" class="svg-inline--fa fa-car" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M39.61 196.8L74.8 96.29C88.27 57.78 124.6 32 165.4 32H346.6C387.4 32 423.7 57.78 437.2 96.29L472.4 196.8C495.6 206.4 512 229.3 512 256V448C512 465.7 497.7 480 480 480H448C430.3 480 416 465.7 416 448V400H96V448C96 465.7 81.67 480 64 480H32C14.33 480 0 465.7 0 448V256C0 229.3 16.36 206.4 39.61 196.8V196.8zM109.1 192H402.9L376.8 117.4C372.3 104.6 360.2 96 346.6 96H165.4C151.8 96 139.7 104.6 135.2 117.4L109.1 192zM96 256C78.33 256 64 270.3 64 288C64 305.7 78.33 320 96 320C113.7 320 128 305.7 128 288C128 270.3 113.7 256 96 256zM416 320C433.7 320 448 305.7 448 288C448 270.3 433.7 256 416 256C398.3 256 384 270.3 384 288C384 305.7 398.3 320 416 320z"></path></svg>
                          </a>
                        </div>
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.factories.country">
                      {{issue.country}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.factories.city">
                      {{issue.city}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.factories.map_gps">
                      <a target="_blank" :href="'https://maps.google.com?q='+issue.lat+','+issue.lon">{{issue.lat}}/{{issue.lon}}</a>
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.factories.product_type">
                      {{issue.product_type}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.factories.start_date">
                      {{issue.start_date}}
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>
          </div>
        </div>

        <!-- CONTACTS -->
        <div class="w-full px-4 mx-auto mt-5" >
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded ">
            <div class="rounded-t mb-0 px-4 py-3 border-0">
              <div class="flow-root">
                    <h3 class="font-semibold text-base text-blueGray-700">Contacts: {{contacts.length}}</h3>
                    <button @click="contacts_filters_view=!contacts_filters_view" class="float-right text-gray-500 border border-gray-200 flex flex-inline items-center px-2 py-1 rounded">
                      <svg class="h-5 w-5 fill-stroke text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z"></path>
                            <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                      <span class="ml-2 text-sm">Config</span>
                    </button>
                    <button @click="tableCsv('contacts-table')" class="mr-2 float-right text-gray-500 border border-gray-200 flex flex-inline items-center px-2 py-1 rounded">
                      <img class="h-4 w-auto" src="/download_icon.svg" alt="Download"/>
                      <span class="ml-2 text-sm">Export</span>
                    </button>
              </div>
            </div>
            <div v-if="contacts_filters_view" class="mb-4">
              <div class="grid grid-cols-4 flex justify-between mb-4">
                <div class="ml-3 flex items-center h-5" v-for="(item, index) in columns.contacts" :key="index">
                  <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="columns.contacts[index]"/>
                  <label class="ml-2 capitalize">{{index.replaceAll('_',' ')}}</label>
                </div>
              </div>
            </div>
            <div class="block w-full overflow-x-auto max-h-96">
              <table class="table-sort contacts items-center bg-transparent w-full border-collapse " id="contacts-table">
                <thead class="sticky top-0 bg-white">
                  <tr>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.contacts.id">
                                  #
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.contacts.name">
                                  Name
                                </th>
                    
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.contacts.category">
                                  Category
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.contacts.subcategory">
                                  Subcategory
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.contacts.map_gps">
                                  Map/GPS
                                </th>
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.contacts.country">
                                  Country
                                </th>
                    
                    <th class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" v-show="columns.contacts.company">
                                  Company
                                </th>
                  </tr>
                </thead>

                <tbody class="overflow-y-scroll">
                  <tr v-for="(contact, index) in contacts" :key="index" :class="[(activeContact === contact.id) ? 'bg-green-400' : '', (index % 2 === 0) ? 'bg-gray-100' : '']" :id="'c_'+contact.id">
                    <td class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left text-blueGray-700 p-2 " v-show="columns.contacts.id">
                      <div class="flex justify-between items-center">
                        <span>
                          <input type="checkbox" name="points" :value="contact.map" v-model="points" class="mr-2"/>
                          <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white w-20 bg-green-400 mr-2 rounded"><a :href="'https://charlie.uabp.eu/contacts/'+contact.id" target="_blank">#{{contact.id}}</a></span>
                        </span>
                        <img :src="getIconContactsTable(contact)" class="w-6 mr-3" /> 
                        <a class="block w-4 h-4 ml-2" target="_blank" :href="'https://www.google.com/maps/dir/?api=1&origin='+center+'&destination='+contact.map+'&travelmode=driving'">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="car" class="svg-inline--fa fa-car" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M39.61 196.8L74.8 96.29C88.27 57.78 124.6 32 165.4 32H346.6C387.4 32 423.7 57.78 437.2 96.29L472.4 196.8C495.6 206.4 512 229.3 512 256V448C512 465.7 497.7 480 480 480H448C430.3 480 416 465.7 416 448V400H96V448C96 465.7 81.67 480 64 480H32C14.33 480 0 465.7 0 448V256C0 229.3 16.36 206.4 39.61 196.8V196.8zM109.1 192H402.9L376.8 117.4C372.3 104.6 360.2 96 346.6 96H165.4C151.8 96 139.7 104.6 135.2 117.4L109.1 192zM96 256C78.33 256 64 270.3 64 288C64 305.7 78.33 320 96 320C113.7 320 128 305.7 128 288C128 270.3 113.7 256 96 256zM416 320C433.7 320 448 305.7 448 288C448 270.3 433.7 256 416 256C398.3 256 384 270.3 384 288C384 305.7 398.3 320 416 320z"></path></svg>
                          </a>
                        </div>
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.contacts.name">
                      {{contact.first_name}} {{contact.last_name}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.contacts.category">
                      {{contact.category}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.contacts.subcategory">
                      {{contact.subcategory}}
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.contacts.map_gps">
                      <a target="_blank" :href="'https://maps.google.com?q='+contact.map">{{contact.map}}</a>
                    </td>
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.contacts.country">
                      {{contact.country}}
                    </td>
                    
                    <td class="h-10 leading-10 border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2" v-show="columns.contacts.company">
                      {{contact.company}}
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LTooltip, LPopup } from 'vue2-leaflet';
import {axiosInstance as client} from "@/util/axios";
import { Icon } from 'leaflet';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import jsPDF from 'jspdf' 
import 'jspdf-autotable'
import domtoimage from "dom-to-image";
import tableSort from "table-sort-js/table-sort.js";
import XLSX from 'xlsx';


delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
name: "Heatmap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPopup,
    tableSort
  },
  mounted() {
    let current = new Date().getFullYear()
    for(let i = 2019; i <= current; i++ ){
      this.years.unshift(i.toString())
      this.year.unshift(i.toString())
    }

    if(localStorage.getItem('heatmapSettings')){
      this.showMap = false
      let data = JSON.parse(localStorage.getItem('heatmapSettings'))
      //console.log(data)
      if(typeof data.trackers != 'undefined') this.tracker = data.trackers.map(function (x) { 
        return parseInt(x, 10); 
      });

      /*this.trackers.filter(function(el){ 
        ////console.log(data.trackers,el.value)
        return data.trackers.includes(el.value.toString()) 
      })*/
      if(typeof data.project != 'undefined') this.project = data.project.map(function (x) { 
        return parseInt(x, 10); 
      });
      if(typeof data.years != 'undefined') this.year = data.years
      if(typeof data.places != 'undefined') this.places = data.places

      if(typeof data.contacts != 'undefined') this.contactsSettings = data.contacts
      if(typeof data.viewAll != 'undefined') this.viewAll = data.viewAll
      if(typeof data.hideContacts != 'undefined') this.hideContacts = data.hideContacts

      if(typeof data.cardSelected != 'undefined') this.cardSelected = data.cardSelected

      this.showMap = true
        
        ////console.log(data.viewAll,this.viewAll)
      this.loadData()

      if(typeof data.places != 'undefined') {
        
        let tempPlaces = []
        data.places.forEach(el => {
          if(el.country == 'All Countries'){
            tempPlaces.push({region: el.region, country: 'All Countries'})
          }
        });

        this.places = tempPlaces

      }
    }
    tableSort()
  },
  
  data() {
    return {
      points: [],
      contactsSettings: [],
      activeContact: null,
      activeIssue: null,
      center: null,
      ready: true,
      visibleMarkers: [],
      visibleMarkersContacts: [],
      tracking: true,
      totCases:0,
      totFactory:0,
      totTrainings:0,
      zoom: 6,
      attribution:'&copy; UNDER ARMOUR INC.',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      issues: [],
      hideIssues: false,
      hideContacts: true,
      showMap: false,
      loading: false,
      region: null,
      country: null,
      tracker: [1,5,4],
      year: [],
      years: [],
      viewAll: true,
      showFeatured: false,
      viewAllIssues: true,
      placeOfInterest: [7,8,27,104],
      cardSelected: {
        one: true,
        two: false
      },
      active: {
        places: true,
        issues: false,
        contacts: false,
        appearance: false
      },
      cases_filters_view: false,
      trainings_filters_view: false,
      factories_filters_view: false,
      contacts_filters_view: false,
      columns: {
        cases: {
          id: true,
          country: true,
          city: true,
          map_gps: true,
          total_units: true,
          product_type: true,
          start_date: true,
          place_of_interest: true,
          /* hiddend */
          custom_type: false, 
          law_enforcement: false,
          vendor: false,
          infringer_1: false,
          infringer_2: false
        },
        trainings: {
          id: true,
          country: true,
          city: true,
          map_gps: true,
          start_date: true,
          /* hiddend */
          subject: false,
          law_enforcement: false,
          vendor: false,
          attendees: false,
          visit: false
        },
        factories: {
          id: true,
          country: true,
          city: true,
          product_type: true,
          map_gps: true,
          start_date: true
        },
        contacts: {
          id: true,
          name: true,
          category: true,
          subcategory: true,
          map_gps: true,
          /* hiddend */
          country: false,
          //city: false,
          company: false
        }
      },
      contactCategory: null,
      contactSubCategory: null,
      contactsCategory: [
        'EXTERNAL',
        'INFRINGER',
        'PARTNER',
        'UA'
      ],
      places: [],
      contactsList: [],
      regions: [
        'AMERICAS',
        'APAC',
        'EMEA',
        'ALL REGIONS'
      ],
      placesOfInterest: [
        {value: 7, text: 'Shop'},
        {value: 8, text: 'Warehouse'},
        {value: 27, text: 'Customs'},
        {value: 104, text: 'Factory'}
      ],
      trackers: [
        {value: 1, text: 'Cases'},
        {value: 5, text: 'Training'},
        {value: 4, text: 'Factory'}
      ],
      countries: {
        'AMERICAS': [
          "All Countries",
          "Anguilla",
          "Antigua and Barbuda",
          "Argentina",
          "Aruba",
          "Bahamas",
          "Barbados",
          "Belize",
          "Bermuda",
          "Bolivia",
          "Bonaire, Saint Eustatius and Saba",
          "Bouvet Island",
          "Brazil",
          "Canada",
          "Cayman Islands",
          "Chile",
          "Colombia",
          "Costa Rica",
          "Cuba",
          "Curaçao",
          "Dominica",
          "Dominican Republic",
          "Ecuador",
          "El Salvador",
          "Falkland Islands (Malvinas)",
          "French Guiana",
          "Grenada",
          "Guadeloupe",
          "Guatemala",
          "Guyana",
          "Haiti",
          "Honduras",
          "Invalid IP",
          "Jamaica",
          "Martinique",
          "Mexico",
          "Montserrat",
          "Netherlands Antilles",
          "Nicaragua",
          "Other",
          "Panama",
          "Paraguay",
          "Peru",
          "Puerto Rico",
          "Saint Barthelemy",
          "Saint Kitts and Nevis",
          "Saint Lucia",
          "Saint Martin",
          "Saint Pierre and Miquelon",
          "Saint Vincent and the Grenadines",
          "Sint Maarten",
          "South Georgia and the South Sandwich Islands",
          "Suriname",
          "Trinidad and Tobago",
          "Turks and Caicos Islands",
          "United States",
          "Uruguay",
          "Venezuela",
          "Virgin Islands, British",
          "Virgin Islands, U.S."
        ],
        'APAC': [
          "All Countries",
          "Aland Islands",
          "American Samoa",
          "Armenia",
          "Australia",
          "Bangladesh",
          "Bhutan",
          "Brunei",
          "Cambodia",
          "China",
          "Cook Islands",
          "Fiji",
          "French Polynesia",
          "Guam",
          "Hong Kong",
          "India",
          "Indonesia",
          "Japan",
          "Kiribati",
          "Laos",
          "Macao",
          "Malaysia",
          "Maldives",
          "Marshall Islands",
          "Micronesia",
          "Mongolia",
          "Myanmar",
          "Nauru",
          "Nepal",
          "New Caledonia",
          "New Zealand",
          "Niue",
          "North Korea",
          "Northern Mariana Islands",
          "Pakistan",
          "Palau",
          "Papua New Guinea",
          "Philippines",
          "Samoa",
          "Singapore",
          "Solomon Islands",
          "South Korea",
          "Sri Lanka",
          "Taiwan",
          "Thailand",
          "Tokelau",
          "Tonga",
          "Tuvalu",
          "Vanuatu",
          "Vietnam",
          "Wallis And Futuna"
        ],
        'EMEA': [
          "All Countries",
          "Afghanistan",
          "Albania",
          "Algeria",
          "Andorra",
          "Angola",
          "Austria",
          "Azerbaijan",
          "Bahrain",
          "Belarus",
          "Belgium",
          "Benin",
          "Bosnia and Herzegovina",
          "Botswana",
          "Bulgaria",
          "Burkina Faso",
          "Burundi",
          "Cameroon",
          "Cape Verde",
          "Central African Republic",
          "Chad",
          "Comoros",
          "Congo",
          "Congo RDC",
          "Côte D'Ivoire",
          "Croatia",
          "Cyprus",
          "Czech Rep.",
          "Denmark",
          "Djibouti",
          "Egypt",
          "Equatorial Guinea",
          "England",
          "Eritrea",
          "Estonia",
          "Ethiopia",
          "Europe",
          "Faroe Islands",
          "Finland",
          "France",
          "Gabon",
          "Gambia",
          "Georgia",
          "Germany",
          "Ghana",
          "Gibraltar",
          "Greece",
          "Greenland",
          "Guernsey",
          "Guinea",
          "Guinea-Bissau",
          "Holy See (Vatican City State)",
          "Hungary",
          "Iceland",
          "Iran",
          "Iraq",
          "Ireland",
          "Isle of Man",
          "Israel",
          "Italy",
          "Jersey",
          "Jordan",
          "Kazakhstan",
          "Kyrgyzstan",
          "Kenya",
          "Kosovo",
          "Kuwait",
          "Latvia",
          "Lebanon",
          "Lesotho",
          "Liberia",
          "Libya",
          "Liechtenstein",
          "Lithuania",
          "Luxembourg",
          "Macedonia",
          "Madagascar",
          "Malawi",
          "Mali",
          "Malta",
          "Mauritania",
          "Mauritius",
          "Mayotte",
          "Moldova Rep.",
          "Monaco",
          "Montenegro",
          "Morocco",
          "Mozambique",
          "Namibia",
          "Netherlands",
          "Niger",
          "Nigeria",
          "Norway",
          "OAPI",
          "Oman",
          "Palestinian Territory",
          "Poland",
          "Portugal",
          "Qatar",
          "Romania",
          "Russian Federation",
          "Rwanda",
          "Saint Helena",
          "San Marino",
          "Sao Tome and Principe",
          "Saudi Arabia",
          "Scotland",
          "Senegal",
          "Serbia",
          "Seychelles",
          "Sierra Leone",
          "Slovakia",
          "Slovenia",
          "Somalia",
          "South Africa",
          "South Sudan",
          "Spain",
          "Sudan",
          "Svalbard and Jan Mayen",
          "Swaziland",
          "Sweden",
          "Syrian Arab Republic",
          "Switzerland",
          "Tadjikistan",
          "Tanzania, United Republic of",
          "Togo",
          "Tunisia",
          "Turkey",
          "Turkmenistan",
          "Uganda",
          "Ukraine",
          "United Arab Emirates",
          "United Kingdom",
          "Uzbekistan",
          "Wales",
          "Western Sahara",
          "WIPO",
          "Yemen",
          "Zambia",
          "Zimbabwe",
        ],
        'INTERNET': [
          "All Countries",
          "Market Place App & Social Media",
          "Domain",
          "Website",
          "Other",
        ],
        'GLOBAL': [
          "Other",
        ],
        "ALL REGIONS": [
          "All Countries"
        ]
      }
    }
  },
  
  computed: {
    setYears(){
      let arr = []
      let current = new Date().getFullYear()
      for(let i = 2019; i <= current; i++ ){
        arr.unshift(i.toString())
      }
      return arr
    },
    getSubCategory(){
      switch(this.contactCategory) {
        case 'INFRINGER':
          return [
            "All",
            "Accomplice",
            "Business Owner",
            "Director",
            "Distributor",
            "Exporter",
            "Factory",
            "Fakes Market",
            "Importer",
            "Liquidator",
            "Manager",
            "Other",
            "Retail",
            "Seller",
            "Transporter",
            "Wholesaler",
            "Wholeseller"
          ]
          break;
        case 'PARTNER':
          return [
            "All",
            "Customs",
            "Investigator",
            "Law Enforcement",
            "Law Firm",
            "Manager",
            "Other"     
          ]
          break;
        case 'UA':
          return [
            "All",
            "Assignee",
            "BH",
            "Clearance House",
            "DH",
            "FH",
            "Friendly Brand",
            "Distributor",
            "Legit Distributor",
            "Legit Manufacturer",
            "Liquidator",
            "Manufacturer",
            "Manager",
            "Office",
            "Other",
            "Seller",
            "Transporter"
          ]
          break;
        case 'EXTERNAL':
          return [
            "All",
            "Informant",
            "Other",
            "Witness"
          ]
          break;
      } 
    },
    cases(){
      let res = []
      //console.log(this.issues)
      this.issues.forEach(issue => {
        if(issue.place_of_interest != null && issue.tracker == '1'){
          ////console.log(issue)
          if(issue.actions != null) res.push(issue)
        }
      });
      if(typeof this.$refs.first != 'undefined'){
        let visible = this.visibleMarkers
        
        let myArrayFiltered = res.filter((el) => {
          return visible.some((f) => {
            return f == el.id;
          })
        })
        ////console.log(myArrayFiltered)
        return myArrayFiltered
      } else {
        return res
      }
    },
    trainings(){
      let res = []
      this.issues.forEach(issue => {
        if(issue.tracker == '5'){
          res.push(issue)
        }
      });
      if(typeof this.$refs.first != 'undefined'){
        let visible = this.visibleMarkers
        //console.log(visible)
        let myArrayFiltered = res.filter((el) => {
          return visible.some((f) => {
            ////console.log(el)
            return f == el.id;
          })
        })
        return myArrayFiltered
      } else {
        return res
      }
    },
    factory(){
      let res = []
      this.issues.forEach(issue => {
        if(issue.tracker == '4'){
          res.push(issue)
        }
      });
      if(typeof this.$refs.first != 'undefined'){
        let visible = this.visibleMarkers
        ////console.log(visible)
        let myArrayFiltered = res.filter((el) => {
          return visible.some((f) => {
            ////console.log(el)
            return f == el.id;
          })
        })
        return myArrayFiltered
      } else {
        return res
      }
    },
    destination(){
      if(!this.points.length) return
      let p = this.points

      return p.slice(1).join('|')
     // let arr = this.points.splice(1)
     // return arr.join('|')
    },

    contacts(){
      if(typeof this.$refs.second != 'undefined'){
        let visible = this.visibleMarkersContacts
        //console.log(visible)
        let myArrayFiltered = this.contactsList.filter((el) => {
          return visible.some((f) => {
            return f == el.id;
          })
        })
        ////console.log(myArrayFiltered)
        return myArrayFiltered
      } else {
        if(typeof this.$refs.first != 'undefined'){
          //console.log('contactsList',this.contactsList)
          let visible = this.visibleMarkers
          //console.log('visible',visible,this.contactsList)
          let myArrayFiltered = this.contactsList.filter((el) => {
            return visible.some((f) => {
              //console.log(el)
              return f == el.id;
            })
          })
          
          return myArrayFiltered
        } else {
          return this.contactsList
        }
      }
    }
  },
  methods: {
    resetSettings(){
      this.tracker = [1,5,4]
      this.project = []
      this.year = this.setYears
      this.places = []
      this.contactsSettings = []
      this.viewAll = true
      this.hideContacts = true
      this.cardSelected = {
        one: true,
        two: false
      }
    },
    tableCsv(table_id, separator = ',') {
        // Select rows from table_id
        var rows = document.querySelectorAll('table#' + table_id + ' tr');
        // Construct csv
        var csv = [];
        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll('td, th');
            for (var j = 0; j < cols.length; j++) {
                // Clean innertext to remove multiple spaces and jumpline (break csv)
                var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                data = data.replace(/"/g, '""');
                // Push escaped string
                row.push('"' + data + '"');
            }
            csv.push(row.join(separator));
        }
        var csv_string = csv.join('\n');
        // Download it
        var filename = 'export_' + table_id + '_' + new Date().toLocaleDateString() + '.csv';
        var link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },

    selectBehavior(item){
      this.cardSelected.one = false
      this.cardSelected.two = false
      this.cardSelected[item] = true
    },   
    printPdf(){
      /** WITH CSS */
      let self = this;
      domtoimage
      .toPng(this.$refs.maps)
      .then(function(dataUrl) {
        var img = new Image();
        img.src = dataUrl;

        const doc = new jsPDF('l','mm',[210, 297]);
        const imgProps= doc.getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        doc.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
        doc.text(10, pdfHeight+15, "Cases: "+self.issues.length)

        doc.autoTable({ 
          html: '#issues-table', 
          startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 10 : pdfHeight+20,
          includeHiddenHtml: true
        })
        
        let finalY = doc.autoTable.previous.finalY;

        doc.text(10, finalY+15, "Trainings: "+self.trainings.length)

        doc.autoTable({ 
          html: '#trainings-table', 
          startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 10 : finalY+20,
          includeHiddenHtml: true
        })

        finalY = doc.autoTable.previous.finalY;

        doc.text(10, finalY+15, "Factory Audit: "+self.factory.length)

        doc.autoTable({ 
          html: '#factory-table', 
          startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 10 : finalY+20,
          includeHiddenHtml: true
        })

        finalY = doc.autoTable.previous.finalY;

        doc.text(10, finalY+15, "Contacts: "+self.contacts.length)

        doc.autoTable({ 
          html: '#contacts-table', 
          startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 10 : finalY+20,
          includeHiddenHtml: true
        })


        const date = new Date();
        const filename =
          "timechart_" +
          date.getFullYear() +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          ("0" + date.getDate()).slice(-2) +
          ("0" + date.getHours()).slice(-2) +
          ("0" + date.getMinutes()).slice(-2) +
          ("0" + date.getSeconds()).slice(-2) +
          ".pdf";

        // PAGE NUMBERING
        // Add Page number at bottom-right
        // Get the number of pages
        const pageCount = doc.internal.getNumberOfPages();

        // For each page, print the page number and the total pages
        for(var i = 1; i <= pageCount; i++) {
             // Go to page i
            doc.setPage(i);
             //Print Page 1 of 4 for example
            doc.text('Page ' + String(i) + ' of ' + String(pageCount),210-20,297-30,null,null,"center");
        }
        doc.save(filename);
      })
      .catch(function(error) {
        console.error("oops, something went wrong!", error);
      });
    },

    getIcon(item) {
      console.log(item.tracker)
      if(!item.tracker || item.tracker=='undefined') return
      if(item.place_of_interest === null && item.tracker != '1'){
        return L.icon({
          iconSize: [31,46],
          iconUrl: require('../../assets/markers/marker-'+item.tracker+'.png')
        });
      }

      if(item.project === '1. OPS' && item.place_of_interest != null ){
        return L.icon({
          iconSize: [31,46],
          iconUrl: require('../../assets/markers/marker-'+item.place_of_interest+'.png')
        });
      }
    },
    getIconContacts(item) {
      if(item.category == 'UA'){
        return L.icon({
          iconSize: [31,46],
          iconUrl: require('../../assets/markers/marker-UA.png')
        });
      }

      if(item.category == 'PARTNER'){
        return L.icon({
          iconSize: [31,46],
          iconUrl: require('../../assets/markers/marker-Partner.png')
        });
      }

      if(item.category == 'INFRINGER'){
        return L.icon({
          iconSize: [31,46],
          iconUrl: require('../../assets/markers/marker-bad.png')
        });
      }

      
    },
    getIconContactsTable(item) {
      if(item.category == 'UA'){
        return require('../../assets/markers/marker-UA.png')
      }

      if(item.category == 'PARTNER'){
        return require('../../assets/markers/marker-Partner.png')
      }

      if(item.category == 'INFRINGER'){
        return require('../../assets/markers/marker-bad.png')
      }

      
    },
    returnIcon(name) {
      if(name === null) return require('../../assets/markers/marker-icon-2x.png')
      ////console.log(name)
      return require('../../assets/markers/marker-'+name+'.png')
    },
    getVisibleMarkers() {
        let markerList = []
        const map = this.$refs.first.mapObject
        //let markerList = []
        map.eachLayer(function(layer) {
            if ((layer instanceof L.Marker) && (map.getBounds().contains(layer.getLatLng()))){
              if(typeof layer.options.id != 'undefined') markerList.push(layer.options.id)
            }
        })

        if(typeof this.$refs.second != 'undefined'){
          const map2 = this.$refs.second.mapObject
          //let markerList = []
          map2.eachLayer(function(layer) {
              if ((layer instanceof L.Marker) && (map2.getBounds().contains(layer.getLatLng()))){
                if(typeof layer.options.id != 'undefined') markerList.push(layer.options.id)
              }
          })
        }
        //console.log(markerList);
      return markerList
    },
    onLocationFound(e) {
        const map = this.$refs.first.mapObject;
        let radius = e.accuracy / 2;
        let customIcon = L.icon({
            iconSize: [24,24],
            iconUrl: require('../../assets/markers/marker-position.png')
          })
        L.marker(e.latlng, {icon: customIcon}).addTo(map)
          .bindPopup("You are within " + radius + " meters from this point").openPopup();
        L.circle(e.latlng, radius).addTo(map)
        let self = this
        
        
        self.visibleMarkers = self.getVisibleMarkers()
        map.on("moveend", function () {
          self.visibleMarkers = self.getVisibleMarkers()
          ////console.log(self.visibleMarkers)
          if(typeof self.$refs.second != 'undefined') {
            const map2 = self.$refs.second.mapObject
            //console.log(map.getZoom())
            map2.setView(map.getCenter(), map.getZoom())
          }
        })

        if(typeof this.$refs.second != 'undefined') {
          const map2 = this.$refs.second.mapObject
          //map2.setView(e.latlng, map.getZoom())
          L.marker(e.latlng, {icon: customIcon}).addTo(map2)
          .bindPopup("You are within " + radius + " meters from this point").openPopup();
          L.circle(e.latlng, radius).addTo(map2)
          self.visibleMarkersContacts = self.getVisibleMarkers()
          ////console.log(self.visibleMarkersContacts)
          map2.on("moveend", function () {
            self.visibleMarkersContacts = self.getVisibleMarkers()
            ////console.log(self.visibleMarkers)
          })
        }

        this.center = map.getCenter().lat+','+map.getCenter().lng

    },
    addContact(){
      ////console.log(this.contactCategory,this.contactSubCategory)
      if(this.contactCategory && this.contactSubCategory ) {
        this.contactsSettings.push({category: this.contactCategory, subcategory: this.contactSubCategory})
      }
    },
    setValue(){
      if(this.region && this.country ) {
        this.places.push({region: this.region, country: this.country})
      }
    },
    switchPanel(value){
      this.active = {
        appearance: false,
        contacts: false,
        issues: false,
        places: false

      }
      this.active[value] = true
    },
    next(from,to){
      this.active[from] = false
      this.active[to] = true
    },
    loadData(){

      /*if(this.hideIssues) {
        this.tracker = []
        this.project = []
        this.years = []
        this.places = []
      }*/

      if(this.hideContacts) this.contactsSettings = []

      let data = {
        'trackers': this.tracker,
        'project': this.project,
        'years': this.year,
        'places': this.places,
        'contacts': this.contactsSettings,
        'viewAll': this.viewAll,
        'hideContacts': this.hideContacts,
        'cardSelected': this.cardSelected,
        'placeOfInterest': this.placeOfInterest
      };

      data.places.forEach(el => {
        if(el.country == 'All Countries'){
          this.countries[el.region].forEach(place => {
            if(place != 'All Countries') data.places.push({region: el.region, country: place})
          })
        }
      });

      data.featured = this.showFeatured

      //console.log(data)
      localStorage.setItem('heatmapSettings', JSON.stringify(data))
      this.showMap = false
      client.post('/heatmap/issues',data).then((response) => {
        //console.log(response)
          if(response.status === 200){
            this.issues = response.data.issues
            console.log(this.issues)
            this.contactsList = response.data.contacts
            this.showMap = true
            let self = this
            setTimeout(function(){ 
              const firstMap = self.$refs.first.mapObject;
              firstMap.addControl(new window.L.Control.Fullscreen());
              if(typeof self.$refs.second != 'undefined'){
                const secondMap = self.$refs.second.mapObject
                secondMap.addControl(new window.L.Control.Fullscreen())

              }
              firstMap.on('locationfound', self.onLocationFound);
              firstMap.locate({setView: true, watch: false, maxZoom: 8});
              
            }, 500);
          }
        }).catch( (error) => {
          //console.log(error)
        }).finally(() => {

        });
    },
    sort(){
      tableSort()
    },
    exportAll(){
      let wb = XLSX.utils.book_new()
      
      wb.SheetNames.push("Cases")
      let ws2 = XLSX.utils.table_to_sheet(document.getElementById('issues-table'));
      wb.Sheets["Cases"] = ws2;

      wb.SheetNames.push("Trainings")
      let ws3 = XLSX.utils.table_to_sheet(document.getElementById('trainings-table'));
      wb.Sheets["Trainings"] = ws3;

      wb.SheetNames.push("Factory Audit")
      let ws4 = XLSX.utils.table_to_sheet(document.getElementById('factory-table'));
      wb.Sheets["Factory Audit"] = ws4;

      wb.SheetNames.push("Contacts")
      let ws5 = XLSX.utils.table_to_sheet(document.getElementById('contacts-table'));
      wb.Sheets["Contacts"] = ws5;
      

      XLSX.writeFile(wb, 'heatmap.xlsx')
    }

  }, 
  watch: {
    visibleMarkers: function (val) {
      let old = this.issues
      this.issues = []
      this.issues = old
      //console.log(this.issues)
      if(typeof this.$refs.second == 'undefined'){
        let oldContacts = this.contactsList
        this.contactsList = []
        this.contactsList = oldContacts
      }
      this.sort()
    },
    visibleMarkersContacts: function (val) {
      ////console.log(val)
      //if(typeof this.$refs.second != 'undefined'){
        let oldContacts = this.contactsList
        this.contactsList = []
        this.contactsList = oldContacts
        this.sort()
      //}
    },
  }
}
</script>

<style scoped>

tbody {
    max-height: 310px;
    overflow-y: auto;
    overflow-x: hidden;
}

.bg-green-400{background-color:#64b581}
</style>
