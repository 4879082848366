<template>
  <div class="mb-5">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
        <!--<label for="company_style" class="block text-sm font-medium text-gray-700">
          Styles Search Tool
        </label>-->
        <div class="inline-flex mt-4 grid md:grid-cols-12 sm:grid-cols-1 gap-5">
          <div class="col-span-11 flex justify-between">
            <input v-model="query" @keyup.enter="search()" type="text" name="company_style" id="company_style" class="p-2 focus:ring-gray-500 flex-1 block w-full rounded-sm sm:text-sm border-gray-300 border-b" placeholder="Type styles here">
            <button v-if="query !== ''" v-on:click="query = ''; stylesContent.donated = []; stylesContent.liquidated = []; stylesContent.stolen = [];">
              <img class="h-4 w-auto" src="/reset_icon.svg" alt="Setting liquidated"/>
            </button>
          </div>
          <div class="col-span-1">
            <button @click="search()" class="inline-flex justify-center p-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100 p-2">
              Search
            </button>
          </div>
        </div>
      </div>
      <div v-if="loading" class="px-4 py-5 bg-white space-y-6 sm:p-6">
        <h1>
          Loading please wait...
        </h1>
      </div>
      <div class="px-4 py-5 bg-white space-y-6 sm:p-6" v-if="stylesContent.liquidated.length">
        <div class="flex justify-between">
          <h1 class="font-500 text-xl">Liquidated (Overall Qty {{Object.values(stylesContent.liquidated).reduce((a, b) => a + b.total_qty, 0)}})</h1>
          <div class="flex flex-wrap items-center content-center text-center">
            <download-csv :data="stylesContent.liquidated" name="liquidated.csv" class="cursor-pointer">
              <div class="flex flex-wrap items-center content-center text-center">
                <div class="ml-3">
                  <img class="h-4 w-auto" src="/download_icon.svg" alt="Download liquidated"/>
                </div>
              </div>
            </download-csv>
            <button class="inline-flex justify-center ml-2 p-1 text-sm font-medium rounded-md text-white" @click="liquidated_filter_view = !liquidated_filter_view">
              <img class="h-4 w-auto" style="fill: #3b82f6;" src="/settings_icon.svg" alt="Setting liquidated"/>
            </button>
          </div>
        </div>
        <div v-if="liquidated_filter_view" class="border-l-2 border-b-2 border-blue-500 mb-4">
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:flex justify-between mb-4">
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.region"/>
              <label class="ml-2">Region</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.country"/>
              <label class="ml-2">Country</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.status"/>
              <label class="ml-2">Status</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.style_number"/>
              <label class="ml-2">Style Number</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.po"/>
              <label class="ml-2">PO</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.party_name_1"/>
              <label class="ml-2">Party Name 1</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.party_name_2"/>
              <label class="ml-2">Party Name 2</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.created_date"/>
              <label class="ml-2">Created Date</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.requested_date"/>
              <label class="ml-2">Requested Date</label>
            </div>
          </div>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:flex justify-between">
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.article_generic"/>
              <label class="ml-2">Article Generic</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.description"/>
              <label class="ml-2">Description</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.gender"/>
              <label class="ml-2">Gender</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.color"/>
              <label class="ml-2">Color</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.size"/>
              <label class="ml-2">Size</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.product_division"/>
              <label class="ml-2">Product Division / Season</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.total_qty"/>
              <label class="ml-2">Total Qty</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.liquidated.sales_document"/>
              <label class="ml-2">Sales Document</label>
            </div>
          </div>
          <br>
        </div>
        <div class="overflow-y-auto max-h-96">
          <table class="min-w-full table-auto text-sm" name="table_liquidated" id="table_liquidated">
            <thead class="sticky top-0 z-40">
            <tr>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-blue-500 w-1 text-white tracking-wider"></th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.region ? '' : 'hidden'">Region</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.country ? '' : 'hidden'">Country</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.status ? '' : 'hidden'">Status</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.style_number ? '' : 'hidden'">Style Number</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.description ? '' : 'hidden'">Description</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.po ? '' : 'hidden'">PO</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.party_name_1 ? '' : 'hidden'">Party Name 1</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.party_name_2 ? '' : 'hidden'">Party Name 2</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.created_date ? '' : 'hidden'">Created Date</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.requested_date ? '' : 'hidden'">Requested Date</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.article_generic ? '' : 'hidden'">Article generic</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.gender ? '' : 'hidden'">Gender</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.color ? '' : 'hidden'">Color</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.size ? '' : 'hidden'">Size</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.product_division ? '' : 'hidden'">Product Division / Season</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.total_qty ? '' : 'hidden'">Total Qty</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.liquidated.sales_document ? '' : 'hidden'">Sales Document</th>
            </tr>
            </thead>
            <tbody v-if="stylesContent.liquidated !== null">
            <tr v-for="(liquidatedItem, index) in stylesContent.liquidated">
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider bg-blue-500 w-1"></td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.region ? '' : 'hidden')]">{{ liquidatedItem.region }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.country ? '' : 'hidden')]">{{ liquidatedItem.country }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.status ? '' : 'hidden')]">{{ liquidatedItem.status }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.style_number ? '' : 'hidden')]">{{ liquidatedItem.style_number }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.description ? '' : 'hidden')]">{{ liquidatedItem.description }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.po ? '' : 'hidden')]">{{ liquidatedItem.po }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.party_name_1 ? '' : 'hidden')]">{{ liquidatedItem.party_name_1 }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.party_name_2 ? '' : 'hidden')]">{{ liquidatedItem.party_name_2 }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.created_date ? '' : 'hidden')]">{{ liquidatedItem.created_date }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.requested_date ? '' : 'hidden')]">{{ liquidatedItem.requested_date }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.article_generic ? '' : 'hidden')]">{{ liquidatedItem.article_generic }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.gender ? '' : 'hidden')]">{{ liquidatedItem.gender }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.color ? '' : 'hidden')]">{{ liquidatedItem.color }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.size ? '' : 'hidden')]">{{ liquidatedItem.size }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.product_division ? '' : 'hidden')]">{{ liquidatedItem.product_division_season }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.total_qty ? '' : 'hidden')]">{{ liquidatedItem.total_qty }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.liquidated.sales_document ? '' : 'hidden')]">{{ liquidatedItem.sales_document }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="px-4 py-5 bg-white space-y-6 sm:p-6" v-if="stylesContent.donated.length">
        <div class="flex justify-between">
          <h1 class="font-500 text-xl">Donated (Overall Qty {{Object.values(stylesContent.donated).reduce((a, b) => a + b.total_qty, 0)}})</h1>
          <div class="flex flex-wrap items-center text-center">
            <download-csv :data="stylesContent.donated" name="donated.csv" class="cursor-pointer">
              <div class="flex flex-wrap items-center content-center text-center">
                <div class="ml-3">
                  <img class="h-4 w-auto" src="/download_icon.svg" alt="Download donated"/>
                </div>
              </div>
            </download-csv>
            <button class="inline-flex justify-center ml-2 p-1 text-sm font-medium rounded-md text-white" @click="donated_filter_view = !donated_filter_view">
              <img class="h-4 w-auto" style="fill: #9bb75b; " src="/settings_icon.svg" alt="Setting donated"/>
            </button>
          </div>
        </div>
        <div v-if="donated_filter_view" class="border-l-2 border-b-2 border-green-500 mb-4">
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:flex justify-between mb-4">
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.region"/>
              <label class="ml-2">Region</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.country"/>
              <label class="ml-2">Country</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.status"/>
              <label class="ml-2">Status</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.style_number"/>
              <label class="ml-2">Style Number</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.po"/>
              <label class="ml-2">PO</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.party_name_1"/>
              <label class="ml-2">Party Name 1</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.party_name_2"/>
              <label class="ml-2">Party Name 2</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.donation_date"/>
              <label class="ml-2">Donation Date</label>
            </div>
          </div>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:flex justify-between">
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.requested_date"/>
              <label class="ml-2">Requested Date</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.article_generic"/>
              <label class="ml-2">Article Generic</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.description"/>
              <label class="ml-2">Description</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.gender"/>
              <label class="ml-2">Gender</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.color"/>
              <label class="ml-2">Color</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.size"/>
              <label class="ml-2">Size</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.product_division"/>
              <label class="ml-2">Product Division / Season</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.donated.total_qty"/>
              <label class="ml-2">Total Qty</label>
            </div>
          </div>
          <br>
        </div>
        <div class="overflow-y-auto max-h-96">
          <table class="min-w-full table-auto text-sm" name="table_donated" id="table_donated">
          <thead class="sticky top-0 z-40">
            <tr>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-green-500 w-1 text-white tracking-wider"></th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.region ? '' : 'hidden'">Region</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.country ? '' : 'hidden'">Country</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.status ? '' : 'hidden'">Status</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.style_number ? '' : 'hidden'">Style Number</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.description ? '' : 'hidden'">Description</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.po ? '' : 'hidden'">PO</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.party_name_1 ? '' : 'hidden'">Party Name 1</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.party_name_2 ? '' : 'hidden'">Party Name 2</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.donation_date ? '' : 'hidden'">Donation Date</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.requested_date ? '' : 'hidden'">Requested Date</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.article_generic ? '' : 'hidden'">Article generic</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.gender ? '' : 'hidden'">Gender</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.color ? '' : 'hidden'">Color</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.size ? '' : 'hidden'">Size</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.product_division ? '' : 'hidden'">Product Division / Season</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.donated.total_qty ? '' : 'hidden'">Total Qty</th>
            </tr>
          </thead>
          <tbody v-if="stylesContent.donated !== null">
            <tr v-for="(donatedItem, index) in stylesContent.donated">
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider bg-green-500 w-1"></td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.region ? '' : 'hidden')]">{{ donatedItem.region }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.country ? '' : 'hidden')]">{{ donatedItem.country }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.status ? '' : 'hidden')]">{{ donatedItem.status }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.style_number ? '' : 'hidden')]">{{ donatedItem.style_number }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.description ? '' : 'hidden')]">{{ donatedItem.description }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.po ? '' : 'hidden')]">{{ donatedItem.po }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.party_name_1 ? '' : 'hidden')]">{{ donatedItem.party_name_1 }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.party_name_2 ? '' : 'hidden')]">{{ donatedItem.party_name_2 }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.donation_date ? '' : 'hidden')]">{{ donatedItem.donation_date }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.donation_date ? '' : 'hidden')]">{{ donatedItem.requested_date }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.article_generic ? '' : 'hidden')]">{{ donatedItem.article_generic }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.gender ? '' : 'hidden')]">{{ donatedItem.gender }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.color ? '' : 'hidden')]">{{ donatedItem.color }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.size ? '' : 'hidden')]">{{ donatedItem.size }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.product_division ? '' : 'hidden')]">{{ donatedItem.product_division_season }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''), (filters.donated.total_qty ? '' : 'hidden')]">{{ donatedItem.total_qty }}</td>
            </tr>
          </tbody>
        </table>
          </div>
      </div>
      <div class="px-4 py-5 bg-white space-y-6 sm:p-6" v-if="stylesContent.stolen.length">
        <div class="flex justify-between">
          <h1 class="font-500 text-xl">Stolen (Overall Qty {{Object.values(stylesContent.stolen).reduce((a, b) => a + b.total_qty, 0)}})</h1>
          <div class="flex flex-wrap items-center text-center">
            <download-csv :data="stylesContent.stolen" name="stolen.csv" class="cursor-pointer">
              <div class="flex flex-wrap items-center content-center text-center">
                <div class="ml-1">
                  <img class="h-4 w-auto" src="/download_icon.svg" alt="Download stolen"/>
                </div>
              </div>
            </download-csv>
            <button class="inline-flex justify-center ml-2  p-1 text-sm font-medium rounded-md text-white" @click="stolen_filter_view = !stolen_filter_view">
              <img class="h-4 w-auto" style="fill: #cc6d65; " src="/settings_icon.svg" alt="Setting stolen"/>
            </button>
          </div>
        </div>
        <div v-if="stolen_filter_view" class="border-l-2 border-b-2 border-red-500">
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:flex justify-between mb-4">
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded bg-red-500" v-model="filters.stolen.region"/>
              <label class="ml-2">Region</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.country"/>
              <label class="ml-2">Country</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.status"/>
              <label class="ml-2">Status</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.style_number"/>
              <label class="ml-2">Style Number</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.ua_incident_nr"/>
              <label class="ml-2">UA Incident NR</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.name_1"/>
              <label class="ml-2">Name 1</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.name_2"/>
              <label class="ml-2">Name 2</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.incident_date"/>
              <label class="ml-2">Incident Date</label>
            </div>
          </div>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:flex justify-between">
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.reported_date"/>
              <label class="ml-2">Reported Date</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.article_generic"/>
              <label class="ml-2">Article Generic</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.description"/>
              <label class="ml-2">Description</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.gender"/>
              <label class="ml-2">Gender</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.color"/>
              <label class="ml-2">Color</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.size"/>
              <label class="ml-2">Size</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.product_division"/>
              <label class="ml-2">Product Division / Season</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" v-model="filters.stolen.total_qty"/>
              <label class="ml-2">Total Qty</label>
            </div>
          </div>
        </div>
        <div class="overflow-y-auto max-h-96">
          <table class="min-w-full table-auto text-sm" name="table_stolen" id="table_stolen">
            <thead class="sticky top-0 z-40">
            <tr>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-red-500 w-1 text-white tracking-wider"></th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.region ? '' : 'hidden'">Region</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.country ? '' : 'hidden'">Country</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.status ? '' : 'hidden'">Status</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.style_number ? '' : 'hidden'">Style Number</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.description ? '' : 'hidden'">Description</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.ua_incident_nr ? '' : 'hidden'">UA Incident NR</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.name_1 ? '' : 'hidden'">Name 1</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.name_2 ? '' : 'hidden'">Name 2</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.incident_date ? '' : 'hidden'">Incident Date</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.reported_date ? '' : 'hidden'">Reported Date</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.article_generic ? '' : 'hidden'">Article generic</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.gender ? '' : 'hidden'">Gender</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.color ? '' : 'hidden'">Color</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.size ? '' : 'hidden'">Size</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.product_division ? '' : 'hidden'">Product Division / Season</th>
              <th scope="col" class="py-3 border-b-2 border-gray-300 text-center leading-4 bg-gray-400 text-white tracking-wider" :class="filters.stolen.total_qty ? '' : 'hidden'">Total Qty</th>
            </tr>
            </thead>
            <tbody v-if="stylesContent.stolen !== null">
            <tr v-for="(stolenItem, index) in stylesContent.stolen">
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider bg-red-500 w-1"></td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.region ? '' : 'hidden')]">{{ stolenItem.region }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.country ? '' : 'hidden')]">{{ stolenItem.country }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.status ? '' : 'hidden')]">{{ stolenItem.status }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.style_number ? '' : 'hidden')]">{{ stolenItem.style_number }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.description ? '' : 'hidden')]">{{ stolenItem.description }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.ua_incident_nr ? '' : 'hidden')]">{{ stolenItem.ua_incident_nr }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.name_1 ? '' : 'hidden')]">{{ stolenItem.name_1 }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.name_2 ? '' : 'hidden')]">{{ stolenItem.name_2 }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.incident_date ? '' : 'hidden')]">{{ stolenItem.incident_date }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.reported_date ? '' : 'hidden')]">{{ stolenItem.reported_date }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.article_generic ? '' : 'hidden')]">{{ stolenItem.article_generic }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.gender ? '' : 'hidden')]">{{ stolenItem.gender }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.color ? '' : 'hidden')]">{{ stolenItem.color }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.size ? '' : 'hidden')]">{{ stolenItem.size }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-gray-500 tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.product_division ? '' : 'hidden')]">{{ stolenItem.product_division_season }}</td>
              <td class="py-3 border-b-2 border-gray-300 text-center leading-4 text-black tracking-wider" :class="[(index%2 ? 'bg-gray-100' : ''),(filters.stolen.total_qty ? '' : 'hidden')]">{{ stolenItem.total_qty }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from "@trevoreyre/autocomplete-vue";
import {axiosInstance as client} from "@/util/axios";
export default {
name: "Styles",
  components: {
    Autocomplete
  },
  data () {
    return {
      loading: false,
      query: '',
      liquidated_filter_view: false,
      donated_filter_view: false,
      stolen_filter_view: false,
      first_load: true,
      filters: {
        liquidated: {
          region: true,
          country: false,
          status: true,
          style_number: true,
          po: false,
          party_name_1: false,
          party_name_2: false,
          created_date: true,
          requested_date: true,
          article_generic: false,
          description: true,
          gender: false,
          color: true,
          size: false,
          product_division: false,
          total_qty: true,
          sales_document: false
        },
        donated: {
          region: true,
          country: false,
          status: true,
          style_number: true,
          po: false,
          party_name_1: false,
          party_name_2: false,
          donation_date: true,
          requested_date: true,
          article_generic: false,
          description: true,
          gender: false,
          color: true,
          size: false,
          product_division: false,
          total_qty: true
        },
        stolen: {
          region: true,
          country: false,
          status: true,
          style_number: true,
          ua_incident_nr: false,
          name_1: false,
          name_2: false,
          incident_date: true,
          reported_date: true,
          article_generic: false,
          description: true,
          gender: false,
          color: true,
          size: false,
          product_division: false,
          total_qty: true
        }
      },
      stylesContent: {
        liquidated: [],
        donated: [],
        stolen: []
      }
    }
  },
  beforeMount() {
    this.getFilters()
    //Da commentare dopo
    /*this.getAllLiquidated()
    this.getAllDonated()
    this.getAllStolen()*/
  },
  methods: {
    getFilters: function (){
      if(JSON.parse(localStorage.getItem('filters'))){
        this.filters = JSON.parse(localStorage.getItem('filters'))
      } else {
        localStorage.setItem('filters', JSON.stringify(this.filters))
      }
      this.first_load = false
    },
    getAllLiquidated: function () {
      client.get('/liquidated/getall').then((response) => {
        if (response.data.status === 'success'){
          this.stylesContent.liquidated = response.data.result;
        }
      }).catch( (error) => {
        console.log(error)
      }).finally( () => {
      });
    },
    getAllDonated: function () {
      client.get('/donated/getall').then((response) => {
        if (response.data.status === 'success'){
          this.stylesContent.donated = response.data.result;
        }
      }).catch( (error) => {
        console.log(error)
      }).finally( () => {
      });
    },
    getAllStolen: function () {
      client.get('/stolen/getall').then((response) => {
        if (response.data.status === 'success'){
          this.stylesContent.stolen = response.data.result;
        }
      }).catch( (error) => {
        console.log(error)
      }).finally( () => {
      });
    },
    search: function () {
      this.loading = true
      if(this.query !== ''){
        client.post('/styles/search',{
          query: this.query
        }).then((response) => {
          if (response.data.status === 'success'){
            this.stylesContent.liquidated = response.data.result.liquidated;
            this.stylesContent.donated = response.data.result.donated;
            this.stylesContent.stolen = response.data.result.stolen;
          }
        }).catch( (error) => {
          console.log(error)
        }).finally( () => {
          this.loading = false
        });
      } else {
        this.loading = false
      }
    }
  },
  watch: {
    filters: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if(!this.first_load){
          localStorage.setItem('filters', JSON.stringify(val))
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
