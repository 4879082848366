import axios from "axios"

const axiosInstance = axios.create({
  baseURL: 'https://backend.uabp.eu/redmine',
  //baseURL: 'http://localhost',
})

export default async ({ Vue }) => {
  Vue.prototype.$axios = axios
}

export { axiosInstance }
