<template>
  <div class="lg:flex">
    <div class="flex-1 min-w-0 pt-2">
      <div class="container mx-auto">
        <div class="flex items-center justify-between">
          <div><h3 class="text-4xl font-600">{{title}}</h3></div>
          <div><img :src="require('@/assets/logo.png')" class="mx-auto h-16 md:h-20 sm:h-16"/></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Header",
  data() {
    return {
      title: null
    }
  },
  beforeMount() {
    let host = window.location.host;
    let hostSplit = host.split('.');
    switch (hostSplit[0]){
        case 'tips':
        case 'report':
        case 'reports':
        case 'report-dev':
        case 'reports-dev':
          this.title = 'Tips from Athlete'
          break;
        case 'distributor':
        case 'distributors':
        case 'distributor-dev':
          this.title = 'Distributor Form'
          break;
        case 'vendors':
        case 'vendor':
        case 'vendors-dev':
        case 'vendor-dev':
          this.title = 'Vendor Submission Form'
          break;
        case 'domain':
        case 'domains':
        case 'domain-dev':
        case 'domains-dev':
          this.title = 'Web Domain Search Tool'
          break;
        case 'verify':
          this.title = 'Web Domain Verify Tool'
          break;
        case 'styles':
        case 'style':
        case 'lds':
        case 'dls':
        case 'dls-dev':
        case 'styles-dev':
        case 'style-dev':
        case 'lds-dev':
          this.title = 'Donated Liquidated Stolen'
          break;
        case 'heatmap':
        case 'heatmaps':
        case 'map':
        case 'heatmap-dev':
        case 'heatmaps-dev':
        case 'map-dev':
          this.title = 'Heatmap'
          break;
        default:
          this.title = 'Report Form'
          break;
      }
  }
}
</script>

<style scoped>

</style>