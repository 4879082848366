<template>
  <div v-if="heightScreen" id="app" class="bg-gradient-to-b from-gray-50 via-gray-100 to-gray-300" :style="minHeightVar">
    <Header class="pb-4 lg:pb-8 sm:pb-4 pt-5"></Header>
    <router-view/>
  </div>
</template>

<script>
import Header from "@/components/Header";

export default {
  components: {
    Header
  },
  beforeMount() {
    this.screenHeight();
  },
  computed: {
    minHeightVar(){
      return {
        'min-height': this.heightScreen+'px'
      }
    }
  },
  data() {
    return {
      heightScreen: null
    }
  },
  methods: {
    screenHeight: function () {
      this.heightScreen = window.screen.height;
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
