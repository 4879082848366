<template>
  <div>
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
        <!--<label for="company_domain" class="block text-md font-medium text-gray-700">
          Web Domain Search Tool
        </label>-->
        <div class="inline-flex mt-4 grid md:grid-cols-12 sm:grid-cols-1 gap-5">
          <div class="col-span-11">
            <input v-model="companyDomain" type="text" name="company_domain" id="company_domain" class="p-2 focus:ring-indigo-500 flex-1 block w-full rounded-md sm:text-sm border border-gray-300 placeholder-gray-400" placeholder="Type domain here">
          </div>
          <div class="col-span-1">
            <button @click="search(companyDomain)" class="inline-flex justify-center p-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 p-2">
              Search
            </button>
          </div>
        </div>
      </div>
      <div v-if="loading" class="px-4 py-5 bg-white space-y-6 sm:p-6">
        <p>
          Loading please wait...
        </p>
      </div>
      <div v-if="viewIssues" class="px-4 py-5 bg-white space-y-6 sm:p-6">
        <table class="min-w-full table-fixed">
          <thead>
            <tr>
              <th class="py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider"></th>
              <th class="py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">#</th>
              <th class="py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">Url</th>
              <th class="py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">Subject</th>
              <th class="py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">Vendor</th>
              <th class="py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">Last action</th>
              
              <th class="py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">Last Action Taken</th>

              <th class="py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">Enforcement Status</th>
              <th class="py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">Update on</th>
            </tr>
          </thead>
          <tr v-for="issue in issues" class="text-sm">
            <td class="border-b-2 border-gray-200 pt-2" style="width:30px"><div class="status" :style="{ background: color(issue.last_action,issue.enforcement_status) }"></div></td>
            <td class="border-b-2 border-gray-200 pt-2"><a target="_blank" :href="'https://charlie.uabp.eu/issues/'+issue.id" class="px-2">#{{issue.id}}</a></td>
            <td class="border-b-2 border-gray-200 pt-2"><p class="px-2">{{issue.website}}</p></td>
            <td class="border-b-2 border-gray-200 pt-2"><p class="px-2">{{issue.subject}}</p></td>
            <td class="border-b-2 border-gray-200 pt-2"><p class="px-2">{{issue.vendor}}</p></td>
            <td class="border-b-2 border-gray-200 pt-2"><span v-if="issue.last_action_date">{{issue.last_action_date | dateParse('YYYY-MM-DD') | dateFormat('YYYY-MM-DD')}}</span></td>
            <td class="border-b-2 border-gray-200 pt-2">{{issue.last_action_taken}}</td>
            <td class="border-b-2 border-gray-200 pt-2">{{issue.enforcement_status}}</td>
            <td class="border-b-2 border-gray-200 pt-2"><span v-if="issue.updated_on">{{issue.updated_on | dateParse('YYYY-MM-DD') | dateFormat('YYYY-MM-DD')}}</span></td>
          </tr>
        </table>
      </div>
      <div v-if="emptyIssues && !created" class="px-4 py-5 bg-white space-y-6 sm:p-6">
        <hr class="mb-5">
        <p class="text-center">No result found</p>
        <div>
          <div class="inline-flex mt-4 grid md:grid-cols-12 sm:grid-cols-1 gap-5">
          <div class="col-span-3">
            <input v-model="reporterEmail" type="email" name="reporter_email" id="reporter_email" class="p-2 focus:ring-indigo-500 flex-1 block  rounded-md sm:text-sm border border-gray-300 w-full" placeholder="Reporter Email">
          </div>
          <div class="col-span-3">
            <button :disabled="!reporterEmail" @click="issue(reporterEmail,companyDomain,true)" class="disabled:opacity-25 inline-flex justify-center p-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 p-2">
              Report Domain
            </button>
          </div>
        </div>
        </div>
      </div>
      <div v-if="created" class="px-4 py-5 bg-white space-y-6 sm:p-6">
        <p>The domain was reported successfully. Thank you.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from "@trevoreyre/autocomplete-vue";
import {axiosInstance as client} from "@/util/axios";
export default {
name: "Verify",
  components: {
    Autocomplete
  },
  data() {
    return {
      companyDomain:'',
      issues: null,
      viewIssues: false,
      emptyIssues: false,
      reporterEmail: '',
      created: false,
      loading: false,
      forceReport: false
    }
  },
  methods: {
     color(last_action,status){
      if(status == 'Under Dispute') return 'yellow';
      if(last_action =='take down accomplished' || last_action =='all infringing contents has been removed' || last_action == 'Whitelisted'){
        return 'green';
      } else if(last_action && (last_action !='take down accomplished' && last_action !='all infringing contents has been removed' && last_action != 'Whitelisted')){
        return 'orange';
      } else {
        return 'red';
      }
    },
    issue(email=false,domain,force=false){
      console.log(email)
      if(!email) return;
      client.post('/domain',{
          'email': email,
          'domain': domain,
          'force': force
        }).then((response) => {
          if(response.data.status === 'success'){
            this.created = true;
          } else {
            this.created = false;
          }
        }).catch( (error) => {
          this.created = false;
          console.log(error)
        }).finally( () => {
        });
    },
    search: function () {
      this.loading = true
      console.log(this.companyDomain)
     let input = this.companyDomain;
      if(input !== ''){
        client.post('/issues',{
          'offset':'0',
          'limit':'10',
          'type':'domains',
          'subject': input,
          'fields': [
            {
              'id': '124',
              'value': input
            }
          ]
        }).then((response) => {
          if(response.data.status === 'success'){
            this.issues = response.data.issues
            this.viewIssues = true;
            this.emptyIssues = false;
          } else {
            this.viewIssues = false;
            this.emptyIssues = true;
          }
        }).catch( (error) => {
          this.viewIssues = false;
          this.emptyIssues = true;
          console.log(error)
        }).finally( () => {
          this.loading = false
        });
      } else {
        this.viewIssues = false;
        this.emptyIssues = true;
      }
    },
  },
}
</script>

<style scoped>
.status{border-radius:30px; width:16px; height: 16px}
::-webkit-input-placeholder { /* Edge */
  color: #000;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #000;
}

::placeholder {
  color: #000;
}
</style>
