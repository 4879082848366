<template>
  <div class="p-4">
    <form @submit.prevent="fetchData" class="flex items-center mb-4">
      <input
        v-model="searchQuery"
        class="p-4 border border-gray-300 rounded mr-2 w-full"
        placeholder="Type here..."
      />
      <button
        type="submit"
        class="p-4 bg-blue-500 text-white rounded flex items-center justify-center"
      >
        <svg
          fill="#FFF"
          height="24px"
          width="auto"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 488.4 488.4"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                d="M0,203.25c0,112.1,91.2,203.2,203.2,203.2c51.6,0,98.8-19.4,134.7-51.2l129.5,129.5c2.4,2.4,5.5,3.6,8.7,3.6
			s6.3-1.2,8.7-3.6c4.8-4.8,4.8-12.5,0-17.3l-129.6-129.5c31.8-35.9,51.2-83,51.2-134.7c0-112.1-91.2-203.2-203.2-203.2
			S0,91.15,0,203.25z M381.9,203.25c0,98.5-80.2,178.7-178.7,178.7s-178.7-80.2-178.7-178.7s80.2-178.7,178.7-178.7
			S381.9,104.65,381.9,203.25z"
              />
            </g>
          </g>
        </svg>
      </button>
    </form>

    <div v-if="results.length" class="text-left">
      <h2 class="text-lg font-semibold mb-4">Risultati:</h2>
      <table class="min-w-full table-auto">
        <thead>
          <tr class="bg-gray-100">
            <th class="px-4 py-2 text-left"></th> <!-- Added column for radio buttons -->
            <th class="px-4 py-2 text-left">Company</th>
            <th class="px-4 py-2 text-left">Last Name</th>
            <th class="px-4 py-2 text-left">Name</th>
            <th class="px-4 py-2 text-left">Vendor Code</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(result, index) in results" :key="index" :class="index % 2 === 0 ? 'bg-gray-100' : ''">
            <td class="border px-4 py-2">
              <input type="radio" :value="result" v-model="selectedResult" @change="emitSelectedResult">
            </td>
            <td class="border px-4 py-2">{{ result.Company }}</td>
            <td class="border px-4 py-2">{{ result.Surname }}</td>
            <td class="border px-4 py-2">{{ result.Name }}</td>
            <td class="border px-4 py-2"><b>{{ result.ID }}</b></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "VendorSearch",
  props: {
    filter: String
  },
  data() {
    return {
      searchQuery: "",
      results: [],
      selectedResult: null, // Holds the selected row's value
    };
  },
  methods: {
    async fetchData() {
      const params = {
        query: this.searchQuery, // il tuo termine di ricerca
        filter: this.filter
        // altri parametri necessari...
      };

      try {
        const response = await axios.get(
          "https://backend.uabp.eu/redmine/contacts",
          { params }
        );
        console.log(response)
        this.results = response.data.contacts; // Assicurati che il percorso sia corretto in base alla risposta delle API
      } catch (error) {
        console.error("Errore durante la ricerca:", error);
      }
    },
    emitSelectedResult() {
      this.$emit('selectionChanged', this.selectedResult); // Emit an event with the selected result
    }
  },
};
</script>
