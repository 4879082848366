<template>
  <div>
    <content-panel></content-panel>
  </div>
</template>

<script>
import ContentPanel from "@/components/ContentPanel";
export default {
  name: 'Home',
  components: {
    ContentPanel
  }
}
</script>
